import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { XymSpacer, ExpenseIcon, Font } from 'components';
import { StyledModeSelect, ModeSelector } from './styles';
import { withTheme } from 'styled-components';

const ModeSelect = withTheme(props => {
  const { theme, type, setType, visible } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  if (!visible) return null;

  return (
    <Fragment>
      <StyledModeSelect>
        <ModeSelector
          $theme={theme}
          $selectedTheme={selectedTheme}
          type={type}
          onClick={() => setType('transactions')}
        >
          <ExpenseIcon
            icon='fa-solid fa-list'
            size={5}
            fontSize={2.5}
            alwaysVisible={true}
          />

          <Font weight='semibold'>
            My transactions...
          </Font>
        </ModeSelector>

        <XymSpacer across={true} />

        <ModeSelector
          $theme={theme}
          $selectedTheme={selectedTheme}
          type={type}
          onClick={() => setType('expenses')}
        >
          <ExpenseIcon
            icon='fa-solid fa-bars-progress'
            size={5}
            fontSize={2.5}
            alwaysVisible={true}
          />

          <Font weight='semibold'>
            My expenses & goals...
          </Font>
        </ModeSelector>
      </StyledModeSelect>

      <XymSpacer />
    </Fragment>
  );
});

export { ModeSelect };