import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, expensesActions } from 'modules';
import { getColor, hexValid, sort, formatNumber, cleanCurrencyString } from 'helpers';
import { Font, XymField, XymSelect, XymConfirmButtons, HR, XymSpacer, NoData } from 'components';
import { Formik, Form } from 'formik';
import styled, { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';

const { names, themes, breakpoints } = appConstants;
const { safeBalanceName } = names;
const { mobile } = breakpoints;
const { light } = themes;

export const Destinations = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 27rem;
  border: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    return lightTheme ? getColor(props, 'lightGrey') : getColor(props, 'lightGrey') + 50;
  }};
  border-radius: 0.25rem;

  input {
    border: unset;
  }

  @media screen and (max-width: ${mobile}rem) {
    width: 100%;
  }
`;

export const FlipTargets = styled('div')`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  color: ${props => getColor(props, 'onPrimary')};
  background-color: ${props => hexValid(props.$bgColor) || getColor(props, 'accent')};
  border: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    return lightTheme ? getColor(props, 'lightGrey') : getColor(props, 'lightGrey') + 50;
  }};
  box-shadow: 0 0.125rem 0.125rem ${props => {
    const lightTheme = props.$selectedTheme === light;
    return getColor(props, lightTheme ? 'lightGrey' : 'black');
  }};

  span {
    margin: -0.125rem 0 0 -0.125rem;
  }
`;

const MoveMoneyForm = withTheme(props => {
  const { theme, from, to, setConfirmContent } = props;
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const { expensesData, safeBalance } = useSelector(state => state.expenses);
  const { selectedAccountId } = useSelector(state => state.institutions);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const accent = theme.modes[selectedTheme]?.accent;
  const secondary = theme.modes[selectedTheme]?.secondary;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const lightTheme = selectedTheme === light;
  const dispatch = useDispatch();

  const updateExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.updateExpense(payload, callbacks));
  }, [ dispatch ]);

  const rebalanceExpense = useCallback((payload, callbacks) => {
    dispatch(expensesActions.rebalanceExpense(payload, callbacks));
  }, [ dispatch ]);

  const fromSources = [
    {
      value: `${safeBalanceName}`,
      label: safeBalanceName,
      note: `${formatNumber(safeBalance)}`,
    },
  ];

  const expenseDestinations = useMemo(() => {
    return expensesData?.map(expense => {
      const { _id, name, isGoal, current } = expense;

      if (!isGoal) return {
        value: _id,
        label: name,
        note: `${formatNumber(current)}`,
      };
    }).filter(item => item);
  }, [ expensesData ]);

  const goalDestinations = useMemo(() => {
    return expensesData?.map(expense => {
      const { _id, name, isGoal, current } = expense;

      if (isGoal) return {
        value: _id,
        label: name,
        note: `${formatNumber(current)}`,
      };
    }).filter(item => item);
  }, [ expensesData ]);

  const destinations = sort([ ...fromSources || [], ...expenseDestinations || [], ...goalDestinations || [] ], 'label');
  const safeToUse = destinations.find(destination => destination.value === fromSources[0].value);
  const firstExpense = destinations.find(destination => destination.value !== safeToUse.value);
  const fromExpense = expensesData?.find(expense => expense._id === from);
  const toExpense = expensesData?.find(expense => expense._id === to);
  const goingToSafeBalance = to === safeBalanceName && fromExpense;
  const comingFromSafeBalance = from === safeBalanceName && toExpense;
  const goingBetweenExpenses = !goingToSafeBalance && !comingFromSafeBalance && fromExpense && toExpense;

  const getDefaultAmount = () => {
    const availableAmount = fromExpense?.onTrackData?.current;
    const catchUpAmount = toExpense?.onTrackData?.amountNeededToCatchUp;
    const stillNeededByDayBeforeDueDate = toExpense?.onTrackData?.stillNeededByDayBeforeDueDate;
    const offTrack = !toExpense?.onTrackData?.onTrack;
    const hasFunds = availableAmount >= catchUpAmount;
    const hasSafeFunds = safeBalance >= catchUpAmount;

    if (goingBetweenExpenses) {
      return offTrack && hasFunds ? catchUpAmount : availableAmount;
    }

    if (goingToSafeBalance && availableAmount) {
      return availableAmount;
    }

    if (comingFromSafeBalance && offTrack) {
      return hasSafeFunds ? catchUpAmount : '';
    }

    if (comingFromSafeBalance && !offTrack) {
      return hasSafeFunds ? stillNeededByDayBeforeDueDate : '';
    }

    return '';
  };

  const defaults = {
    amount: getDefaultAmount(),
    from: from || safeToUse?.value || '',
    to: to || firstExpense?.value || '',
  };

  const validationSchema = yup.object().shape({
    amount: yup.string().required('Amount is required.')
      .test('amount', 'Amount must be greater than 0.', value => {
        const amount = _.toNumber(value?.replace(/[$, ]/g, ''));
        return amount > 0;
      })
      .test(yup.ref('from'), 'Insufficient source balance', (value, meta) => {
        const { from } = meta.parent;
        const source = destinations.find(destination => destination.value === from);
        const sourceBalance = _.toNumber(source?.note?.replace(/[$,() ]/g, ''));
        const amount = _.toNumber(value?.replace(/[$,() ]/g, ''));

        return amount <= sourceBalance;
      }),
    from: yup.string().required('Source is required.'),
    to: yup.string().required('Destination is required.'),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { amount, from, to } = values;

    const amountAsNumber = _.isNumber(amount) ? amount : cleanCurrencyString(amount);
    const goingToSafeBalance = to === safeBalanceName && fromExpense;
    const comingFromSafeBalance = from === safeBalanceName && toExpense;
    const goingBetweenExpenses = !goingToSafeBalance && !comingFromSafeBalance && fromExpense && toExpense;

    const callbacks = {
      onSuccess: () => setConfirmContent(null),
      onComplete: () => setSubmitting(false),
    };

    if (goingToSafeBalance) {
      const updatePayload = {
        id: from,
        linkedAccountId: selectedAccountId,
        current: +(fromExpense.current - amountAsNumber).toFixed(2),
        useDailyAutoFundCalculations: fromExpense.useDailyAutoFundCalculations,
        meta: { noLoadingState: true },
        token,
      };

      updateExpense(updatePayload, callbacks);
    }

    if (comingFromSafeBalance) {
      const updatePayload = {
        id: to,
        linkedAccountId: selectedAccountId,
        current: +(toExpense.current + amountAsNumber).toFixed(2),
        useDailyAutoFundCalculations: toExpense.useDailyAutoFundCalculations,
        meta: { noLoadingState: true },
        token,
      };

      updateExpense(updatePayload, callbacks);
    }

    if (goingBetweenExpenses) {
      const transferPayload = {
        linkedAccountId: selectedAccountId,
        to,
        from,
        amount: +(amountAsNumber).toFixed(2),
        meta: { noLoadingState: true },
        token,
      };

      rebalanceExpense(transferPayload, callbacks);
    }
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          {_.isEmpty(expensesData) && (
            <>
              <XymSpacer />

              <NoData
                text='No expenses found'
                subText='Create at least one expense to move money.'
                icon='fa-solid fa-right-left'
              />
            </>
          )}

          {!_.isEmpty(expensesData) && (
            <>
              <XymField
                form={form}
                name='amount'
                placeholder='Dollar signs and commas okay'
                textColor={accent}
                label={<Font size={0.875} weight='semibold'>How much do you want to move?</Font>}
              />

              <Destinations $theme={theme} $selectedTheme={selectedTheme}>
                <XymSelect
                  form={form}
                  data={destinations.filter(destination => destination.value !== form.values.to)}
                  name='from'
                  noResultsText='Source not found'
                  privacy={privacyMode}
                  noSpacing={true}
                />

                <FlipTargets
                  $theme={theme}
                  $selectedTheme={selectedTheme}
                  $bgColor={lightTheme ? secondary : darkGrey}
                  onClick={() => {
                    const { values, setFieldValue } = form;
                    const { from, to } = values;
                    setFieldValue('from', to);
                    setFieldValue('to', from);
                  }}
                >
                  <i className='fas fa-exchange-alt fa-rotate-90' />
                </FlipTargets>
                <HR
                  visible={true}
                  color={lightTheme ? lightGrey : lightGrey + 50}
                />

                <XymSpacer />

                <XymSelect
                  form={form}
                  data={!_.isEmpty(expensesData)
                    && destinations.filter(destination => destination.value !== form.values.from)
                  }
                  name='to'
                  noResultsText='Destination not found'
                  noSpacing={true}
                  privacy={privacyMode}
                />
              </Destinations>
            </>
          )}

          <XymSpacer size={1.5} />

          <XymConfirmButtons
            form={form}
            confirmText='Move'
            privacy={privacyMode}
            disabled={_.isEmpty(expensesData) || _.isEqual(form.values.from, form.values.to)}
            setConfirmContent={setConfirmContent}
          />
        </Form>
      )}
    </Formik>
  );
});

export { MoveMoneyForm };