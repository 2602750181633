import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appConstants, institutionsActions, expensesActions } from 'modules';
import { animate, institutionByAccountId } from 'helpers';
import { Font, XymSearch, ManualTransactionForm, XymDropdown } from 'components';
import { RemoveTransactionsConfirm } from './RemoveTransactionsConfirm';
import { TitleArea } from './styles';
import { withTheme } from 'styled-components';
import { Button } from 'xerum';
import _ from 'lodash';

const { light } = appConstants.themes;

const ActivityTitle = withTheme(props => {
  const { theme, setConfirmContent } = props;

  const { tokenInfo } = useSelector(state => state.auth);
  const { mobileMode, tabletMode, tabletLandscapeMode } = useSelector(state => state.app);
  const { expensesDataLoading } = useSelector(state => state.expenses);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const {
    institutionsData,
    transactionsData,
    transactionsDataLoading,
    institutionDataLoading,
    selectedAccountId,
    paginationData,
  } = useSelector(state => state.institutions);

  const [ manualTransactionForm, setManualTransactionForm ] = useState(false);

  const institution = useMemo(() => {
      return institutionByAccountId({ institutionsData, accountId: selectedAccountId });
    }, [ selectedAccountId, institutionsData ]);

  const { pageLimit } = paginationData || {};
  const dataLoading = institutionDataLoading || transactionsDataLoading || expensesDataLoading;
  const isSelfManaged = institution?.selfManaged;
  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;
  const darkGrey = theme.modes[selectedTheme]?.darkGrey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const titleColor = lightTheme ? darkGrey : offWhite;
  const animationRef = useRef(null);
  const searchRef = useRef(null);
  const selectedAccountIdRef = useRef(selectedAccountId);
  const dispatch = useDispatch();

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const getTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getTransactions(payload, callbacks));
  }, [ dispatch ]);

  const setSearchQuery = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.setSearchQuery(payload, callbacks));
  }, [ dispatch ]);

  const handleAnimationIn = useCallback(() => {
    animate({ ref: animationRef.current, offset: -10, duration: 0.2 });
  }, []);

  const handleAnimationOut = () => {
    animate({
      ref: animationRef.current,
      offset: -10,
      transitionOut: true,
      onComplete: () => setManualTransactionForm(!manualTransactionForm),
    });
  };

  const resetSearch = useCallback(() => {
    setSearchQuery('');

    if (searchRef.current) {
      searchRef.current.value = '';
    }
  }, [ setSearchQuery ]);

  const handleSearch = args => {
    const { forceRefresh, search } = args;

    const syncPayload = {
      token,
      linkedAccountId: selectedAccountIdRef.current,
      pageLimit,
      search: search || '',

      // Prevents calling to Plaid, unless force checking updates.
      existingDataOnly: !forceRefresh,
    };

    const callbacks = {
      onSuccess: () => {
        const balancesPayload = { token, linkedAccountId: selectedAccountId };
        if (forceRefresh) getBalances(balancesPayload);
      },
    };

    getTransactions(syncPayload, callbacks);
    setSearchQuery(search);

    if (forceRefresh) resetSearch();
  };

  useEffect(() => {
    resetSearch();
    selectedAccountIdRef.current = selectedAccountId;

    return () => resetSearch();
  }, [ selectedAccountId, searchRef, resetSearch, setSearchQuery ]);

  useEffect(() => {
      if (manualTransactionForm) handleAnimationIn();
    }, [ manualTransactionForm, handleAnimationIn ]);

  return (
    <TitleArea $isSelfManaged={isSelfManaged}>
      {desktopMode && (
        <Font id='activity' size={1.25} weight='semibold' color={titleColor}>
          Activity
        </Font>
      )}

      {!isSelfManaged && (
        <Button
          id='refresh'
          theme={theme}
          selectedTheme={selectedTheme}
          type='button'
          noText={true}
          icon={`fa-solid fa-refresh ${dataLoading ? 'fa-spin' : ''}`}
          width={1}
          height={1}
          buttonType='transparent'
          pill={true}
          textSize={1.25}
          color={onPrimary}
          disabled={dataLoading}
          callback={() => handleSearch({ forceRefresh: true })}
        />
      )}

      {isSelfManaged && (
        <>
          <Button
            id='removeManualTransactions'
            theme={theme}
            selectedTheme={selectedTheme}
            type='button'
            noText={true}
            icon='fa-solid fa-broom'
            width={1}
            height={1}
            buttonType='transparent'
            pill={true}
            textSize={1.25}
            color={onPrimary}
            disabled={dataLoading || transactionsDataLoading || _.isEmpty(transactionsData)}
            callback={() => {
              const payload = <RemoveTransactionsConfirm setConfirmContent={setConfirmContent} />;
              setConfirmContent(payload);
            }}
          />

          <Button
            id='addTransaction'
            theme={theme}
            selectedTheme={selectedTheme}
            type='button'
            noText={true}
            icon='fa-solid fa-plus-circle'
            width={1}
            height={1}
            buttonType='transparent'
            pill={true}
            textSize={1.25}
            color={onPrimary}
            disabled={dataLoading}
            callback={() => !manualTransactionForm ? setManualTransactionForm(true) : handleAnimationOut()}
          />

          <XymDropdown
            id='manualTransactionHolder'
            ref={animationRef}
            visible={manualTransactionForm}
            setVisible={handleAnimationOut}
            width={'calc(100% - 2rem)'}
            maxWidth={27}
            verticalPadding={0.5}
            horizontalPadding={0.5}
            posX={1}
            posY={mobileMode ? 8.9 : 2.5}
          >
            <ManualTransactionForm close={handleAnimationOut} />
          </XymDropdown>
        </>
      )}

      <XymSearch
        id='activitySearch'
        ref={searchRef}
        placeholder='Merchant, amount, date(s), category...'
        visible={true}
        callback={search => handleSearch({ search })}
      />
    </TitleArea>
  );
});

export { ActivityTitle };