import { cleanCurrencyString, cleanString, stripNumbersAndRepeats } from 'helpers';
import { allDateFormats, csvSerializationHeaders, appConstants } from 'modules';
import * as fileSaver from 'file-saver';
import moment from 'moment';
import _ from 'lodash';

const customStartCase = str => {
  const lowercaseWords = [ 'of', 'the', 'and', 'in', 'on', 'at', 'for', 'with', 'a', 'an', 'but', 'or', 'to' ];

  return str
    ?.split(/(\s+|-|')/)
    .map((word, index, array) => {
      // Check if the word is a three to four letter uppercase acronym
      if (word.match(/^[A-Z]{3,4}$/)) {
        return word; // Preserve the uppercase acronym
      }

      // Lowercase certain words unless they are the first word
      if (lowercaseWords.includes(word.toLowerCase()) && index !== 0) {
        return word.toLowerCase();
      }

      // Capitalize the first letter of other words
      if (word.match(/^[a-zA-Z]/) && (index === 0 || array[index - 1] !== "'")) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }

      return word;
    })
    .join('');
};

export {
  customStartCase,
};

export const parseCsv = data => {
  const rows = data.split('\n').filter(Boolean);
  const headers = rows[0].match(/(?:"([^"]+)"|([^,]+))/g).map(header => (
    _.toLower(_.snakeCase(header.replace(/"/g, ''))).trim()
  ));

  const parsedData = rows.slice(1).map(row => {
    const values = row.match(/(?:"([^"]+)"|([^,]+))/g).map(value => (
      value.replace(/^"|"$/g, '').trim()
    ));

    const rowObject = {};
    headers.forEach((header, index) => rowObject[header] = values[index] || null);

    return rowObject;
  });

  return parsedData;
};

export const getCategoryById = args => {
  const { id, categories } = args;

  if (id && !_.isEmpty(categories)) {
    const categoryName = categories.find(category => +category._id === +id)?.name || null;
    return categoryName;
  }

  return null;
};

const escapeCsvValue = value => {
  if (value === null || value === undefined) return '""'; // Null to empty quotes
  const strValue = String(value); // Convert to string
  return `"${strValue.replace(/"/g, '""')}"`; // Escape quotes inside strings
};

export const serializeTransactionsToCsv = args => {
  const { transactionsData, categories, accountInfo, callback, addNotification } = args;
  const { names } = appConstants;
  const appName = names.appName;
  const headers = csvSerializationHeaders;

  const getFailMessage = () => {
    if (_.isEmpty(transactionsData)) return 'No transactions to export.';
    if (_.isEmpty(categories)) return 'Categories not loaded, unable to continue export.';

    return 'An account is required to export.';
  };

  if (_.isEmpty(transactionsData) || _.isEmpty(categories) || _.isEmpty(accountInfo)) {

    const failNotification = {
      message: getFailMessage(),
      type: 'error',
    };

    addNotification?.(failNotification);
    return '';
  }

  const rows = transactionsData.map(transaction => {
    const { data, metadata } = transaction || {};
    const { authorized_date, merchant_name, amount, pending } = data || {};
    const { categoryId, autoSpendExpenseId, memo } = metadata || {};
    const category = getCategoryById({ id: categoryId, categories });

    const rowData = [
      escapeCsvValue(authorized_date),
      escapeCsvValue(merchant_name),
      escapeCsvValue(amount * -1),
      pending,
      escapeCsvValue(category),
      escapeCsvValue(autoSpendExpenseId),
      escapeCsvValue(memo),
    ];

    return rowData.join(',');
  });

  const finalCsv = [ headers.join(','), ...rows ].join('\n');
  const accountName = accountInfo?.name ? `${_.camelCase(accountInfo.name)}_` : '';
  const accountMask = accountInfo?.mask ? `${accountInfo.mask}_` : '';
  const exportedTransactions = new Blob([ finalCsv ], { type: 'application/csv' });
  const filename = `${accountName}${accountMask}${appName}_transactions.csv`;

  fileSaver.saveAs(exportedTransactions, filename);
  callback?.(finalCsv);
};

export const normalizeCsvData = data => {
  const { parsedData, categories } = data;

  const getPendingValue = status => {
    const validStatuses = [ 'pending', 'yes', 'true' ];
    return status && validStatuses.includes(_.toLower(status));
  };

  const normalizedData = parsedData?.map(obj => {
    const workingObject = Object.keys(obj).reduce((previous, key) => {
      const value = obj[key];
      const isDate = _.toLower(key).includes('date');
      const hasInflow = _.toLower(key).includes('inflow') || _.toLower(key).includes('deposit');
      const hasOutflow = _.toLower(key).includes('outflow') || _.toLower(key).includes('withdraw');
      const isMerchant = _.toLower(key).includes('description') || _.toLower(key).includes('payee');
      const isAmount = _.toLower(key).includes('amount') || hasOutflow || hasInflow;
      const isPending = _.toLower(key).includes('status') || _.toLower(key).includes('pending');
      const hasCategory = _.toLower(key).includes('category');
      const hasMemo = _.toLower(key).includes('memo') || _.toLower(key).includes('note');
      const hasAutoSpend = _.toLower(key).includes('auto_spend');
      const nullable = true;
      const valueAsNumber = isAmount && cleanCurrencyString(value, nullable);
      const amount = isAmount && (hasOutflow ? -valueAsNumber : valueAsNumber);

      if (isAmount && !isNaN(amount) && !_.isEmpty(value)) {
        return { ...previous, amount: amount ?? null };
      }

      if (_.isEmpty(previous.authorized_date) && isDate) {
        const parsedDate = moment(value, allDateFormats, false);
        const authorized_date = parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : null;

        return { ...previous, authorized_date };
      }

      if (_.isEmpty(previous.merchant_name) && isMerchant) {
        const keepNumbers = true;
        const clean = true;

        return {
          ...previous,
          merchant_name: stripNumbersAndRepeats(_.startCase(_.toLower(value)), keepNumbers, clean) || null,
        };
      }

      if (_.isEmpty(previous.pending) && isPending) {
        const pending = getPendingValue(value);
        return { ...previous, pending };
      }

      if (_.isEmpty(previous.category) && hasCategory) {
        const cleanCategoryValue = cleanString(_.toLower(value));

        const normalizedCategory = categories?.find(category => _.toLower(category.name) === cleanCategoryValue)
          || categories?.find(category => {
            const sourceIncludesExisting = _.includes(cleanCategoryValue, _.toLower(category.name));
            const existingIncludesSource = _.includes(_.toLower(category.name), cleanCategoryValue);

            return sourceIncludesExisting || existingIncludesSource;
          }) || categories?.find(category => {
            const categoryWords = _.words(_.toLower(category.name));
            const inputWords = _.words(cleanCategoryValue);

            return _.some(inputWords, word => categoryWords.includes(word));
          }) || null;

        return {
          ...previous,
          categoryId: !_.isEmpty(categories) ? (normalizedCategory?._id || null) : null,
        };
      }

      if (_.isEmpty(previous.memo) && hasMemo) {
        return { ...previous, memo: value || null };
      }

      if (_.isEmpty(previous.autoSpendExpenseId) && hasAutoSpend) {
        return { ...previous, autoSpendExpenseId: value || null };
      }

      return previous;
    }, {});

    return workingObject;
  });

  return normalizedData;
};