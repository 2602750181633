import { institutionsConstants } from 'modules/institutions/institutionsConstants';
import { request } from 'helpers';
import StateManager from 'state-wrangler';

const { actions, selectors } = institutionsConstants;

const initial = {
  [selectors.STATE_KEY_SLIDE_OVER_TRANSACTION_ID]: null,
  [selectors.STATE_KEY_SELECTED_ACCOUNT_ID]: null,
  [selectors.STATE_KEY_SEARCH_QUERY]: '',
  [selectors.STATE_KEY_TRENDS]: {},
  [selectors.STATE_KEY_INCOME_SOURCES]: {},
};

const institutionsReducer = (initialState = initial, action = {}) => {
  const { payload, meta } = action;
  const state = new StateManager(initialState);

  switch(action.type) {
    // =============== Slide over ===============
    case actions.SET_SLIDE_OVER_TRANSACTION_ID:
      return state.update(selectors.STATE_KEY_SLIDE_OVER_TRANSACTION_ID, payload);



    // =============== Search Query ===============
    case actions.SET_SEARCH_QUERY:
      return state.update(selectors.STATE_KEY_SEARCH_QUERY, payload);



    // =============== Plaid token flows ===============
    // Get Link Token
    case request(actions.GET_LINK_TOKEN).start:
      return state.update(selectors.STATE_KEY_LINK_TOKEN_LOADING, true);

    case request(actions.GET_LINK_TOKEN).success:
      return state.update(selectors.STATE_KEY_LINK_TOKEN, payload);

    case request(actions.GET_LINK_TOKEN).complete:
      return state.update(selectors.STATE_KEY_LINK_TOKEN_LOADING, false);

    // Set Link Token
    case actions.SET_LINK_TOKEN:
      return state.update(selectors.STATE_KEY_LINK_TOKEN, payload);

    // Exchange Public Token
    case request(actions.EXCHANGE_PUBLIC_TOKEN).start:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, true);

    case request(actions.EXCHANGE_PUBLIC_TOKEN).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTIONS, payload: payload.institutions },
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload: payload.selectedAccount },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.EXCHANGE_PUBLIC_TOKEN).complete:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, false);

    // Update Plaid Link
    case request(actions.UPDATE_PLAID_LINK).start:
      return state.update(selectors.STATE_KEY_LINK_TOKEN_LOADING, true);

    case request(actions.UPDATE_PLAID_LINK).success:
      return state.update(selectors.STATE_KEY_LINK_TOKEN, payload);

    case request(actions.UPDATE_PLAID_LINK).complete:
      return state.update(selectors.STATE_KEY_LINK_TOKEN_LOADING, false);

    // Remove Access Token
    case request(actions.REMOVE_INSTITUTION).start:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, true);

    case request(actions.REMOVE_INSTITUTION).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTIONS, payload: payload.institutions },
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload: payload.selectedAccount },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.REMOVE_INSTITUTION).complete:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, false);



    // =============== Institutions Data ===============
    // Get Institutions data
    case request(actions.GET_INSTITUTIONS_DATA).start:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTION_DATA_LOADING, payload: true },
      ]);

    case request(actions.GET_INSTITUTIONS_DATA).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTIONS, payload: payload.institutions },
        { method: 'update', key: selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload: payload.selectedAccount },
      ]);

    case request(actions.GET_INSTITUTIONS_DATA).complete:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTION_DATA_LOADING, payload: false },
      ]);

    // Set Selected Account ID
    case actions.SET_SELECTED_ACCOUNT_ID:
      return state.update(selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload);

    // Update Institution Error State
    case request(actions.UPDATE_INSTITUTION_ERROR_STATE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTIONS, payload: payload.institutions },
        { method: 'update', key: selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload: payload.selectedAccount },
      ]);



    // =============== Purge Unsubscribed Data ===============
    // Purge unsubscribed user data
    case request(actions.PURGE_UNSUBSCRIBED_USER_DATA).success:
      return initialState;



    // =============== Manual Transactions ===============
    // Add Transaction
    case request(actions.ADD_TRANSACTION).start:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.ADD_TRANSACTION).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.ADD_TRANSACTION).complete:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, false);

    // Remove Transaction
    case request(actions.REMOVE_TRANSACTION).start:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.REMOVE_TRANSACTION).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.REMOVE_TRANSACTION).complete:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, false);

    // Remove Manual Transactions
    case request(actions.REMOVE_MANUAL_TRANSACTIONS).start:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.REMOVE_MANUAL_TRANSACTIONS).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.REMOVE_MANUAL_TRANSACTIONS).complete:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, false);



    // =============== Transactions ===============
    // Set Transactions Loading
    case actions.SET_TRANSACTIONS_DATA_LOADING:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, payload);

    // Set Transactions Impporting
    case actions.SET_TRANSACTIONS_IMPORTING:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_IMPORTING, payload);

    // Get Transactions from the Database
    case request(actions.GET_TRANSACTIONS_DATA).start:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, !meta?.noLoadingState);

    case request(actions.GET_TRANSACTIONS_DATA).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.GET_TRANSACTIONS_DATA).complete:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA_LOADING, false);

    // Update a single transaction's metadata
    case request(actions.UPDATE_TRANSACTION).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    // Import transactions from a file
    case request(actions.IMPORT_TRANSACTIONS_FROM_FILE).start:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_IMPORTING, true);

    case request(actions.IMPORT_TRANSACTIONS_FROM_FILE).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.IMPORT_TRANSACTIONS_FROM_FILE).complete:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_IMPORTING, false);

    // Clear Transactions
    case actions.CLEAR_TRANSACTIONS_DATA:
      return state.update(selectors.STATE_KEY_TRANSACTIONS_DATA, payload);



    // =============== Self-Managed Institutions ===============
    // Add Self-Managed Institution
    case request(actions.ADD_SELF_MANAGED_INSTITUTION).start:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, true);

    case request(actions.ADD_SELF_MANAGED_INSTITUTION).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTIONS, payload: payload.institutions },
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload: payload.selectedAccount },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.ADD_SELF_MANAGED_INSTITUTION).complete:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, false);

    // Update Self-Managed Institution
    case request(actions.UPDATE_SELF_MANAGED_INSTITUTION).start:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, true);

    case request(actions.UPDATE_SELF_MANAGED_INSTITUTION).success:
      return state.merge([
        { method: 'update', key: selectors.STATE_KEY_INSTITUTIONS, payload: payload.institutions },
        { method: 'update', key: selectors.STATE_KEY_TRANSACTIONS_DATA, payload: payload.transactions.pageData },
        { method: 'update', key: selectors.STATE_KEY_PAGINATION_DATA, payload: payload.transactions.pagination },
        { method: 'update', key: selectors.STATE_KEY_SELECTED_ACCOUNT_ID, payload: payload.selectedAccount },
        { method: 'update', key: selectors.STATE_KEY_TRENDS, payload: payload.trends },
        { method: 'update', key: selectors.STATE_KEY_INCOME_SOURCES, payload: payload.incomeSources },
      ]);

    case request(actions.UPDATE_SELF_MANAGED_INSTITUTION).complete:
      return state.update(selectors.STATE_KEY_INSTITUTION_DATA_LOADING, false);



    default:
      return initialState;
  }
};

export { institutionsReducer };