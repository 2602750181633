import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Footnote, XymSpacer } from 'components';
import _ from 'lodash';

const Note = props => {
  const { visible, type, expenseMode } = props;
  const { transactionsData } = useSelector(state => state.institutions);
  const { expensesData } = useSelector(state => state.expenses);
  const data = expenseMode ? expensesData : transactionsData;
  const modeElements = expenseMode ? 'expenses and goals' : type;

  if (!visible) return null;

  return (
    <Fragment>
      <Footnote maxWidth={38}>
        {!_.isEmpty(data) && (
          <>
            Exporting will only include {modeElements} for the selected account.
            To back up multiple accounts, export each one individually {expenseMode ? '— ' : '.'}
          </>
        )}

        {expenseMode
          && !_.isEmpty(data)
          && `auto-fund sources will need to be re-assigned to newly imported ${modeElements}.`
        }

        {expenseMode
          && _.isEmpty(data)
          && `Auto-fund sources will need to be re-assigned to newly imported ${modeElements}.`
        }
      </Footnote>

      <XymSpacer size={0.125} />
    </Fragment>
  );
};

export { Note };