import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { ExpenseIcon, Font, XymSpacer, P } from 'components';
import { ImportExportMode } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const Merge = withTheme(forwardRef((props, ref) => {
  const { theme, visible, mergeActive, type, expenseMode, setImportData, setImportMode, setIsMergeOperation } = props;

  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { transactionsData } = useSelector(state => state.institutions);
  const { expensesData } = useSelector(state => state.expenses);
  const data = expenseMode ? expensesData : transactionsData;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const modeElements = expenseMode ? 'expenses and goals' : type;

  if (!visible) return null;

  return (
    <ImportExportMode
      $theme={theme}
      $selectedTheme={selectedTheme}
      $active={mergeActive}
      $visible={!_.isEmpty(data)}
      onClick={() => {
        ref.current.value = '';
        setImportData(null);
        setImportMode(true);
        setIsMergeOperation(true);
        ref.current.click();
      }}
    >
      <ExpenseIcon
        icon='fa-solid fa-code-merge'
        bgColorDark={mergeActive && paynesGrey}
        size={3}
        fontSize={1.5}
        alwaysVisible={true}
      />

      <div>
        <Font weight='semibold'>
          Merge backup {type} with existing {type}.
        </Font>

        <XymSpacer size={0.125} />

        <P size={0.75}>
          Keeps existing {modeElements}, while adding imported {type}.

          {expenseMode && (
            <>
              &nbsp;Expenses that already exist with the same name will be skipped &mdash; keeping the existing version.
            </>
          )}
        </P>
      </div>
    </ImportExportMode>
  );
}));

export { Merge };