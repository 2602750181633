import { useCallback, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Font, XymSpacer } from 'components';
import { appConstants, preferencesActions } from 'modules';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';
import { flags } from 'utility';
import { updateLocalStorage } from 'helpers';

const { themes, spacing, names } = appConstants;
const { privacyName, selectedThemeName } = names;
const { smallGap, gap } = spacing;
const { light, dark } = themes;

const NavButtons = withTheme(props => {
  const { theme, handleMoveMoney, handleImportExport, handleAutoFundSources } = props;
  const { mobileMode } = useSelector(state => state.app);
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { features: { autoFunding } } = flags;

  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const accentHover = theme.modes[selectedTheme]?.accentHover;
  const dispatch = useDispatch();

  const updateUserPreferences = useCallback(payload => {
    dispatch(preferencesActions.updateUserPreferences(payload));
  }, [ dispatch ]);

  const setPrivacyMode = useCallback(payload => {
    dispatch(preferencesActions.setPrivacyMode(payload));
  }, [ dispatch ]);

  const setSelectedTheme = useCallback(payload => {
    dispatch(preferencesActions.setSelectedTheme(payload));
  }, [ dispatch ]);

  useEffect(() => {
    setPrivacyMode(privacyMode);
  }, [ privacyMode, setPrivacyMode ]);

  useEffect(() => {
    setSelectedTheme(selectedTheme);
  }, [ selectedTheme, setSelectedTheme ]);

  return (
    <Fragment>
      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        id='relocatedMoveMoney'
        noText={!mobileMode}
        padding={mobileMode && `${smallGap}rem ${gap}rem`}
        text={mobileMode && (
          <>
            <XymSpacer size={0.375} />
            <Font size={0.625}>
              Move
            </Font>
          </>
        )}
        column={true}
        buttonType='transparent'
        icon='fa-solid fa-right-left'
        color={onPrimary}
        hoverColor={accentHover}
        callback={handleMoveMoney}
      />

      {autoFunding && (
        <Button
          theme={theme}
          selectedTheme={selectedTheme}
          id='relocatedAutoFundSources'
          noText={!mobileMode}
          padding={mobileMode && `${smallGap}rem ${gap}rem`}
          text={mobileMode && (
            <>
              <XymSpacer size={0.375} />
              <Font size={0.625}>
                Funding
              </Font>
            </>
          )}
          column={true}
          buttonType='transparent'
          icon='fa-solid fa-money-bills'
          color={onPrimary}
          hoverColor={accentHover}
          callback={handleAutoFundSources}
        />
      )}

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        noText={!mobileMode}
        padding={mobileMode && `${smallGap}rem ${gap}rem`}
        text={mobileMode && (
          <>
            <XymSpacer size={0.375} />
            <Font size={0.625}>
              Backup
            </Font>
          </>
        )}
        column={true}
        buttonType='transparent'
        icon={'fa-solid fa-clone'}
        color={onPrimary}
        hoverColor={accentHover}
        callback={handleImportExport}
      />

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        noText={!mobileMode}
        padding={mobileMode && `${smallGap}rem ${gap}rem`}
        text={mobileMode && (
          <>
            <XymSpacer size={0.375} />
            <Font size={0.625}>
              Privacy
            </Font>
          </>
        )}
        column={true}
        buttonType='transparent'
        icon={privacyMode ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'}
        color={onPrimary}
        hoverColor={accentHover}
        callback={() => {
          setPrivacyMode(!privacyMode);
          updateUserPreferences({ [privacyName]: !privacyMode, token });
        }}
      />

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        noText={!mobileMode}
        padding={mobileMode && `${smallGap}rem ${gap}rem`}
        text={mobileMode && (
          <>
            <XymSpacer size={0.375} />
            <Font size={0.625}>
              Theme
            </Font>
          </>
        )}
        column={true}
        buttonType='transparent'
        icon={selectedTheme === light ? 'fa-solid fa-sun' : 'fa-solid fa-moon'}
        color={onPrimary}
        hoverColor={accentHover}
        callback={() => {
          const newTheme = selectedTheme === light ? dark : light;
          setSelectedTheme(newTheme);
          updateLocalStorage(selectedThemeName, newTheme);
          updateUserPreferences({ [selectedThemeName]: newTheme, token });
        }}
      />
    </Fragment>
  );
});

export { NavButtons };