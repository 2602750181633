import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, rootActions, appConstants } from 'modules';
import { Font, XymField, XymConfirmButtons, XymSpacer } from 'components';
import { Formik, Form } from 'formik';
import { Checkbox, FieldError } from 'xerum';
import { withTheme } from 'styled-components';
import * as yup from 'yup';

const { themes } = appConstants;
const { light } = themes;

const DeleteAccountForm = withTheme(props => {
  const { theme, setConfirmContent } = props;
  const { tokenInfo, userInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const lightTheme = selectedTheme === light;
  const accent = theme.modes[selectedTheme]?.accent;
  const white = theme.modes[selectedTheme]?.white;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const logout = useCallback(() => dispatch(rootActions.logout()), [ dispatch ]);

  const deleteUser = useCallback((payload, callbacks) => {
    dispatch(authActions.deleteUser(payload, callbacks));
  }, [ dispatch ]);

  const defaults = {
    email: '',
    irreversibleAcknwoledgement: false,
    recreationAcknowledgement: false,
    dataPurgeAcknwoledgement: false,
  };
  const validationSchema = yup.object().shape({
    email: yup.string()
      .email('Invalid email.')
      .required('Email is required.')
      .oneOf([ `${userInfo.email}` ], 'Email must match your account email.')
      .trim(),
    irreversibleAcknwoledgement: yup.boolean()
      .oneOf([ true ], 'Acknowledgement required.'),
    dataPurgeAcknwoledgement: yup.boolean()
      .oneOf([ true ], 'Acknowledgement required.'),
    recreationAcknowledgement: yup.boolean()
      .oneOf([ true ], 'Acknowledgement required.'),
  });

  const handleSubmit = args => {
    const { values, setSubmitting } = args;
    const { email } = values;

    const payload = { token, email };
    const callbacks = {
      onSuccess: () => {
        setConfirmContent(null);
        logout?.();
      },
      onComplete: () => setSubmitting(false),
    };

    deleteUser(payload, callbacks);
  };

  return (
    <Formik
      initialValues={defaults}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting } = handlers;
        handleSubmit({ values, setSubmitting });
      }}
    >
      {form => (
        <Form>
          <Font weight='semibold'>Delete account confirmation</Font>

          <XymSpacer />

          <XymField
            form={form}
            name='email'
            placeholder='Type your email, to confirm...'
            spacing={0.5}
          />

          <XymSpacer size={0.5} />

          <Checkbox
            theme={theme}
            selectedTheme={selectedTheme}
            form={form}
            name='irreversibleAcknwoledgement'
            checkColor={lightTheme ? accent : white}
            label={<Font>I understand this is irreversible.</Font>}
          />

          <FieldError theme={theme} selectedTheme={selectedTheme} name='irreversibleAcknwoledgement' />

          <XymSpacer size={0.5} />

          <Checkbox
            theme={theme}
            selectedTheme={selectedTheme}
            form={form}
            name='dataPurgeAcknwoledgement'
            checkColor={lightTheme ? accent : white}
            label={<Font>I understand my data will be purged.</Font>}
          />

          <FieldError theme={theme} selectedTheme={selectedTheme} name='dataPurgeAcknwoledgement' />

          <XymSpacer size={0.5} />

          <Checkbox
            theme={theme}
            selectedTheme={selectedTheme}
            form={form}
            name='recreationAcknowledgement'
            checkColor={lightTheme ? accent : white}
            label={<Font>I understand a new account will be required.</Font>}
          />

          <FieldError theme={theme} selectedTheme={selectedTheme} name='recreationAcknowledgement' />

          <XymSpacer size={1.5} />

          <XymConfirmButtons
            form={form}
            confirmText='Proceed'
            confirmColor={error}
            confirmHoverColor={errorHover}
            setConfirmContent={setConfirmContent}
          />
        </Form>
      )}
    </Formik>
  );
});

export { DeleteAccountForm };