import { useRef, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { NoData, TransactionForm, XymSpacer } from 'components';
import { StyledContent, Map, Details } from './styles';
import { TxTitle } from './TxTitle';
import { TxTimeAndType } from './TxTimeAndType';
import { TxAddress } from './TxAddress';
import { useGoogleMaps } from 'hooks';
import _ from 'lodash';

const { VITE_GOOGLE_MAPS_API_KEY: apiKey } = process.env;

const TxContent = withTheme(props => {
  const { theme, setConfirmContent } = props;

  const {
    transactionsData,
    institutionsData,
    selectedAccountId,
    slideOverTransactionId,
    searchQuery,
  } = useSelector(state => state.institutions);

  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);

  const transaction = useMemo(() => {
    return transactionsData?.find?.(tx => tx.metadata?.transactionId === slideOverTransactionId) || {};
  }, [ transactionsData, slideOverTransactionId ]);

  const workingAccount = institutionsData?.find(institution => {
    const workingAccount = institution.accounts.find(account => account.account_id === selectedAccountId);
    return workingAccount ? institution : null;
  });

  const mapRef = useRef();
  const fallbackMessage = useRef();
  const selfManaged = workingAccount?.selfManaged;
  const offWhite = theme.modes[selectedTheme]?.offWhite;
  const locationData = useMemo(() => transaction?.data?.location, [ transaction ]);
  const mapArgs = { apiKey, mapRef, locationData, transaction, fallbackMessage, offWhite, privacyMode };
  const googleMaps = useGoogleMaps(mapArgs);

  useEffect(() => {
    googleMaps?.setMap();
  }, [ googleMaps, slideOverTransactionId ]);

  const buildContent = () => {
    if (!_.isEmpty(transaction)) {
      return (
        <Details>
          <TxTitle transaction={transaction} />
          <TxTimeAndType transaction={transaction} />
          <TxAddress transaction={transaction} />
        </Details>
      );
    }
  };

  return (
    <StyledContent>
      <Map ref={mapRef} $theme={theme} $selectedTheme={selectedTheme} $visible={selfManaged}>
        <NoData
          icon={`fa-solid fa-${privacyMode ? 'lock' : 'location-dot'}`}
          text={privacyMode ? 'Privacy mode is enabled.' : 'No location data.'}
          subText={privacyMode ? 'Disable privacy mode to view location.' : 'Payment location will appear here.'}
        />
      </Map>

      <XymSpacer size={selfManaged ? 0.125 : 1} />
      {buildContent()}
      <XymSpacer size={1.5} />

      <TransactionForm
        transaction={transaction}
        searchQuery={searchQuery}
        setConfirmContent={setConfirmContent}
      />
    </StyledContent>
  );
});

export { TxContent };