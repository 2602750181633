import { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { institutionsActions } from 'modules';
import { institutionByAccountId } from 'helpers';
import { XymSpacer, XymConfirm } from 'components';
import { ActivityTitle } from './ActivityTitle';
import { ActivityList } from './ActivityList';
import { StyledActivity } from './styles';
import { Pagination } from 'xerum';
import { withTheme } from 'styled-components';

import _ from 'lodash';

const Activity = withTheme(props => {
  const { theme } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { bannerContent } = useSelector(state => state.app);

  const [ confirmContent, setConfirmContent ] = useState(null);

  const {
    institutionsData,
    transactionsData,
    selectedAccountId,
    paginationData,
    searchQuery,
  } = useSelector(state => state.institutions);

  const {
    generalPreferences: { selectedTheme },
  } = useSelector(state => state.preferences);

  const institution = useMemo(() => {
    return institutionByAccountId({ institutionsData, accountId: selectedAccountId });
  }, [ selectedAccountId, institutionsData ]);

  const dispatch = useDispatch();
  const { pageLimit, currentPage, totalPages } = paginationData || {};
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const isSelfManaged = institution?.selfManaged;

  const getTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.getTransactions(payload, callbacks));
  }, [ dispatch ]);

  const handlePageChange = pageNumber => {
    const payload = {
      token,
      pageLimit,
      pageNumber,
      linkedAccountId: selectedAccountId,
      search: !_.isEmpty(searchQuery) ? searchQuery : '',
      existingDataOnly: true,
    };

    getTransactions(payload);
  };

  return (
    <StyledActivity $bannerContent={bannerContent}>
      <ActivityTitle isSelfManaged={isSelfManaged} setConfirmContent={setConfirmContent} />

      <XymSpacer size={_.isEmpty(transactionsData) ? 2 : 1} />

      <ActivityList setConfirmContent={setConfirmContent} />

      <Pagination
        theme={theme}
        selectedTheme={selectedTheme}
        currentPage={currentPage || 1}
        totalPages={totalPages || 1}
        numberSize={0.9375}
        markerWidth={0.5}
        truncateLimit={3}
        boldActive={true}
        visible={totalPages > 1}
        prevIcon='fa-solid fa-caret-left'
        nextIcon='fa-solid fa-caret-right'
        onPageChange={handlePageChange}
      />

      <XymConfirm
        confirmContent={confirmContent}
        useOverflow={false}
        blank={true}
        onClose={() => setConfirmContent(null)}
      />
    </StyledActivity>
  );
});

export { Activity };