import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions, expensesActions, appConstants, institutionsActions } from 'modules';
import { exportExpenses, normalizeExpenses, serializeTransactionsToCsv } from 'helpers';
import { Operations } from './Operations';
import { ModeSelect } from './ModeSelect';
import { XymSpacer, Font, XymConfirmButtons, NoData, Center } from 'components';
import { withTheme } from 'styled-components';
import { Button, Loading } from 'xerum';
import _ from 'lodash';

const { themes } = appConstants;
const light = themes.light;

const ImportExportWizard = withTheme(props => {
  const {
    theme,
    setConfirmContent,
    defaultType,
    defaultImportMode,
    defaultImportData,
    defaultFile,
    restrictDataMode,
  } = props;

  const [ isMergeOperation, setIsMergeOperation ] = useState(false);
  const [ importMode, setImportMode ] = useState(defaultImportMode || null);
  const [ importData, setImportData ] = useState(defaultImportData || null);
  const [ type, setType ] = useState(defaultType || null);

  const { categories } = useSelector(state => state.app);
  const { institutionsData, selectedAccountId } = useSelector(state => state.institutions);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { tokenInfo } = useSelector(state => state.auth);
  const { expensesData, expensesImporting } = useSelector(state => state.expenses);
  const { transactionsData, transactionsImporting } = useSelector(state => state.institutions);

  const lightTheme = selectedTheme === light;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const replaceAllActive = importMode && !isMergeOperation && !_.isEmpty(importData);
  const mergeActive = importMode && isMergeOperation && !_.isEmpty(importData);
  const exportActive = importMode === false && !isMergeOperation;
  const noType = _.isEmpty(type);
  const dataTypeExpenses = _.toLower(type) === 'expenses';
  const noSelection = !replaceAllActive && !mergeActive && !exportActive;
  const onAccent = theme.modes[selectedTheme]?.onAccent;
  const grey = theme.modes[selectedTheme]?.grey;
  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const disabledTextColor = lightTheme ? grey : paynesGrey;

  const importingExpenses = importMode && dataTypeExpenses;
  const exportingExpenses = !importMode && dataTypeExpenses;
  const importingTransactions = importMode && !dataTypeExpenses;
  const exportingTransactions = !importMode && !dataTypeExpenses;

  const disableForExpenses = useMemo(() => {
    if (!dataTypeExpenses) return false;

    const hasData = exportingExpenses
      ? !_.isEmpty(expensesData)
      : importingExpenses && !_.isEmpty(importData);

    return expensesImporting || !hasData || noSelection;
  }, [
    expensesImporting,
    importData,
    expensesData,
    importingExpenses,
    exportingExpenses,
    dataTypeExpenses,
    noSelection,
  ]);

  const disableForTransactions = useMemo(() => {
    if (dataTypeExpenses) return false;

    const hasData = exportingTransactions
      ? !_.isEmpty(transactionsData)
      : importingTransactions && !_.isEmpty(importData);

    return transactionsImporting || !hasData || noSelection;
  }, [
    transactionsImporting,
    importData,
    transactionsData,
    importingTransactions,
    exportingTransactions,
    dataTypeExpenses,
    noSelection,
  ]);

  const dispatch = useDispatch();

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const importExpenses = useCallback((payload, callbacks) => {
    dispatch(expensesActions.importExpenses(payload, callbacks));
  }, [ dispatch ]);

  const importTransactionsFromFile = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.importTransactionsFromFile(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const removeFile = () => {
    setType(null);
    setImportData(null);
    setImportMode(null);
    setIsMergeOperation(false);
  };

  const cleanup = () => {
    removeFile();
    setConfirmContent(null);
  };

  useEffect(() => {
    return () => removeFile();
  }, []);

  const getConfirmText = () => {
    if (!selectedAccountId) return 'Awaiting link...';
    if (!importData && _.isEmpty(expensesData)) return `No ${type}`;
    if (noSelection) return 'Awaiting selection...';

    if (expensesImporting || transactionsImporting) {
      return (
        <Loading
          theme={theme}
          selectedTheme={selectedTheme}
          isLoading={expensesImporting || transactionsImporting}
          iconColor={expensesImporting || transactionsImporting ? disabledTextColor : onAccent}
          textColor={expensesImporting || transactionsImporting ? disabledTextColor : onAccent}
          noFailIcon={true}
          text={<Font size={1} weight='semibold'>Importing...</Font>}
          failText={<Font weight='semibold'>Import failed</Font>}
        />
      );
    }

    if (exportActive) return `Export ${type}`;
    if (mergeActive) return `Merge ${type}`;

    return `Restore ${type}`;
  };

  const handleImportExport = () => {
    if (importingExpenses) {
      const callbacks = {
        onSuccess: cleanup,
      };

      const payload = {
        importData,
        isMergeOperation,
        linkedAccountId: selectedAccountId,
        meta: { noLoadingState: true },
        token,
      };

      const expenseImportPayload = {
        importData,
        isMergeOperation,
        addNotification,
        callback: () => importExpenses(payload, callbacks),
      };

      normalizeExpenses(expenseImportPayload);
      return;
    }

    if (exportingExpenses) {
      const expenseExportPayload = {
        institutionsData,
        selectedAccountId,
        expensesData,
        callback: cleanup,
      };

      exportExpenses(expenseExportPayload);
      return;
    }

    if (importingTransactions && !_.isEmpty(importData)) {
      const callbacks = {
        onSuccess: () => {
          const balancesPayload = { token, linkedAccountId: selectedAccountId };
          getBalances(balancesPayload);
          cleanup();
        },
      };

      const payload = {
        importData,
        isMergeOperation,
        linkedAccountId: selectedAccountId,
        meta: { noLoadingState: true },
        token,
      };

      importTransactionsFromFile(payload, callbacks);
      return;
    }

    if (exportingTransactions && !_.isEmpty(transactionsData)) {
      let accountInfo = {};

      institutionsData?.find(institution => {
        const workingAccount = institution.accounts.find(account => account.account_id === selectedAccountId);
        if (workingAccount) (accountInfo = workingAccount);
      });

      const args = { transactionsData, categories, accountInfo, addNotification, callback: cleanup };
      serializeTransactionsToCsv(args);
    }
  };

  return (
    <div>
      <Font weight='semibold' size={1}>
        {!noType && !restrictDataMode && (
          <Font>
            <Button
              theme={theme}
              selectedTheme={selectedTheme}
              color={selectedTheme === light ? paynesGrey : onPrimary}
              hoverColor={selectedTheme === light ? paynesGrey : onPrimary}
              pill={true}
              noText={true}
              height={2}
              width={2}
              icon='fa-solid fa-arrow-left'
              callback={removeFile}
            />

            <XymSpacer across={true} />
          </Font>
        )}

        {noType && 'What would you like to back up or restore?'}
        {!noType && selectedAccountId && 'Select option to begin...'}
      </Font>

      <XymSpacer />

      {!selectedAccountId && (
        <Center>
          <NoData
            icon='fa-solid fa-link'
            text='No linked institution(s)'
            subText='Link to begin importing or exporting data.'
          />

          <XymSpacer size={2} />
        </Center>
      )}

      <ModeSelect visible={selectedAccountId && noType} type={type} setType={setType} />

      <Operations
        type={type}
        expenseMode={dataTypeExpenses}
        mergeActive={mergeActive}
        visible={selectedAccountId && type}
        replaceAllActive={replaceAllActive}
        importData={importData}
        exportActive={exportActive}
        defaultFile={defaultFile}
        setIsMergeOperation={setIsMergeOperation}
        setImportData={setImportData}
        setImportMode={setImportMode}
      />

      <XymConfirmButtons
        hideConfirmButton={noType || !selectedAccountId}
        confirmText={getConfirmText()}
        setConfirmContent={setConfirmContent}
        disabled={disableForExpenses || disableForTransactions}
        callback={handleImportExport}
      />
    </div>
  );
});

export { ImportExportWizard };