import { appActions } from 'modules';
import _ from 'lodash';

export const prepPath = path => {
  if (!_.startsWith(path, '/')) return `/${path}`;
  return path;
};

export const handleNotify = (dispatch, data) => {
  const messages = data?.message || data?.messages || [];
  const warningMessages = data?.warning || data?.warnings || [];
  const errorMessages = data?.error || data?.errors || [];

  if (!_.isEmpty(messages) || !_.isEmpty(warningMessages) || !_.isEmpty(errorMessages)) {
    const addNotification = payload => dispatch(appActions?.addNotification(payload));
    const messageArray = _.isArray(messages) ? messages : [ messages ];
    const warningMessageArray = _.isArray(warningMessages) ? warningMessages : [ warningMessages ];
    const errorMessageArray = _.isArray(errorMessages) ? errorMessages : [ errorMessages ];

    const buildNotification = (item, typeFallback) => {
      const { message, msg, icon, type } = item;

      if(_.isArray(item)) {
        item.forEach(subItem => addNotification(buildNotification(subItem, typeFallback)));
        return;
      }

      return {
        message: message || msg || item,
        icon,
        type: type || typeFallback,
      };
    };

    if (!_.isEmpty(messageArray)) {
      messageArray.forEach(item => addNotification(buildNotification(item, 'info')));
    }

    if (!_.isEmpty(warningMessageArray)) {
      warningMessageArray.forEach(item => addNotification(buildNotification(item, 'warning')));
    }

    if (!_.isEmpty(errorMessageArray)) {
      errorMessageArray.forEach(item => addNotification(buildNotification(item, 'error')));
    }

    if (!_.isEmpty(messages)) addNotification(buildNotification(messages, 'info'));
    if (!_.isEmpty(warningMessages)) addNotification(buildNotification(warningMessages, 'warning'));
    if (!_.isEmpty(errorMessages)) addNotification(buildNotification(errorMessages, 'error'));
  }
};

const handleOtherResponses = args => {
  const { dispatch, res, onFail } = args;
  const { status, statusText } = res || {};
  const text = `: ${statusText || ''}`;
  const message = {
    message: `${status || ''}${statusText ? text : ' Response'}.`,
    type: status < 400 ? 'info' : 'error',
  };

  if (status >= 400) onFail?.({ ...res || {}, ...message });
  handleNotify(dispatch, { message });
};

export const handleInitialRes = async args => {
  const { res, onFail, dispatch, allowStatusNotifications } = args;
  const { status, statusText } = res || {};
  const text = `: ${statusText || ''}`;
  const message = {
    message: `${status || ''}${statusText ? text : ' Response'}.`,
    type: status < 400 ? 'info' : 'error',
  };

  // 429 notify is being sent from back-end rate limiter, directly.
  if (res?.status >= 300 && res?.status !== 429) {
    const resArgs = { dispatch, res, onFail };
    allowStatusNotifications ? handleOtherResponses(resArgs) : onFail?.({ ...res, ...message });
  }

  if (res?.status !== 504) return res.json();
};