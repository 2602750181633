
export const allDateFormats = [
  'MMMM D YYYY', 'MMMM Do YYYY', 'MMMM D, YYYY', 'MMMM Do, YYYY',
  'MMM D YYYY', 'MMM Do YYYY', 'MMM D, YYYY', 'MMM Do, YYYY',
  'MM/DD/YYYY', 'MM-DD-YYYY', 'M/D/YYYY', 'M-D-YYYY', 'D', 'Do',
  'MM/DD/YY', 'MM-DD-YY', 'M/D/YY', 'M-D-YY',
  'MMMM D', 'MMMM Do', 'MMM D', 'MMM Do', 'MMMM', 'MMM',
  'MM/DD', 'MM-DD', 'M/D', 'M-D',
  'YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY-MM-D', 'YYYY/MM/D',
  'MMMM YYYY', 'MMM YYYY', 'YYYY',
];

export const supportedCurrencies = [
  {
    name: 'dollar',
    symbol: '$',
    abbreviation: 'USD',
  },
  {
    name: 'dollar',
    symbol: '$',
    abbreviation: 'CAD',
  },
  {
    name: 'pound',
    symbol: '£',
    abbreviation: 'GBP',
  },
  {
    name: 'euro',
    symbol: '€',
    abbreviation: 'EUR',
  },
];

export const csvSerializationHeaders = [
  'Date',
  'Payee',
  'Amount',
  'Pending',
  'Category',
  'Auto-Spend ID',
  'Memo',
];

export const validCsvHeaders = [
  'date',
  'merchant',
  'name',
  'description',
  'type',
  'label',
  'payee',
  'category',
  'category id',
  'account',
  'note',
  'amount',
  'status',
  'pending',
  'memo',
  'outflow',
  'inflow',
  'deposit',
  'withdraw',
  'balance',
  'auto spend',
];

export const twoLetterWords = new Set([
  'aa', 'ab', 'ad', 'ae', 'ag', 'ah', 'ai', 'al', 'am', 'an', 'ar', 'as', 'at', 'aw', 'ax', 'ay',
  'ba', 'be', 'bi', 'bo', 'by',
  'da', 'de', 'di', 'do',
  'ed', 'ef', 'eh', 'el', 'em', 'en', 'er', 'es', 'et', 'ex',
  'fa',
  'go',
  'ha', 'he', 'hi', 'ho',
  'id', 'if', 'in', 'is', 'it',
  'jo',
  'ka', 'ki',
  'la', 'li', 'lo',
  'ma', 'me', 'mi', 'mm', 'mo', 'mu',
  'my',
  'na', 'ne', 'no', 'nu',
  'od', 'oe', 'of', 'oh', 'oi', 'ok', 'om', 'on', 'op', 'or', 'os', 'ow', 'ox', 'oy',
  'pa', 'pe', 'pi',
  're',
  'sh', 'si', 'so',
  'ta', 'ti', 'to',
  'uh', 'um', 'un', 'up', 'us', 'ut',
  'we', 'wo',
  'xi', 'xu',
  'ya', 'ye', 'yo',
]);