import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from 'modules';
import { Font, DeleteAccountForm, UpdateEmailForm, XymConfirm, XymSpacer } from 'components';
import { DeleteMessage, StyledActions } from './styles';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';

const Actions = withTheme(props => {
  const { theme, verified } = props;
  const { userInfo, tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const [ emailConfirmContent, setEmailConfirmContent ] = useState(null);
  const [ deleteConfirmContent, setDeleteConfirmContent ] = useState(null);
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const lightGrey = theme.modes[selectedTheme]?.lightGrey;
  const dispatch = useDispatch();

  const sendVerificationEmail = useCallback((payload, callbacks) => {
    dispatch(authActions.sendVerificationEmail(payload, callbacks));
  }, [ dispatch ]);

  const handleDeleteAccount = () => {
    const payload = <DeleteAccountForm setConfirmContent={setDeleteConfirmContent} />;
    setDeleteConfirmContent(payload);
  };

  const handleUpdateEmail = () => {
    const payload = <UpdateEmailForm setConfirmContent={setEmailConfirmContent} />;
    setEmailConfirmContent(payload);
  };

  const handleVerifyEmail = () => {
    const payload = { newEmail: userInfo?.email, token, resendRequest: true };
    sendVerificationEmail(payload);
  };

  return (
    <StyledActions>
      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        text={<Font weight='medium' mobileSize={0.875}>Update email</Font>}
        callback={handleUpdateEmail}
      />

      <XymSpacer size={0.5} mobileSize={0.5} />

      {!verified && (
        <>
          <Button
            theme={theme}
            selectedTheme={selectedTheme}
            text={<Font weight='medium' mobileSize={0.875}>Resend verification email</Font>}
            callback={handleVerifyEmail}
          />

          <XymSpacer size={0.5} mobileSize={0.5} />
        </>
      )}

      <Button
        theme={theme}
        selectedTheme={selectedTheme}
        text={<Font weight='medium' mobileSize={0.875}>Delete account</Font>}
        color={error}
        hoverColor={errorHover}
        disabled={false} // TODO: Disable if subscription is active
        callback={handleDeleteAccount}
      />

      <XymSpacer size={0.5} />

      <DeleteMessage>
        {/* Only show if subscription is active */}
        <Font size={0.865} color={lightGrey} weight='semibold'>
          An expired subscription is required for account deletion.
        </Font>
      </DeleteMessage>

      <XymConfirm
        confirmContent={emailConfirmContent}
        blank={true}
        onClose={() => setEmailConfirmContent(null)}
      />

      <XymConfirm
        confirmContent={deleteConfirmContent}
        blank={true}
        onClose={() => setDeleteConfirmContent(null)}
      />
    </StyledActions>
  );
});

export { Actions };