import { useRef, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader, XymSpacer } from 'components';
import { handleFileUpload } from 'helpers';
import { appActions, expensesActions } from 'modules';
import { RemoveAndRestore } from './RemoveAndRestore';
import { Merge } from './Merge';
import { Export } from './Export';
import { FileArea } from './FileArea';
import { Note } from './Note';
import { ImportExportContainer } from './styles';
import _ from 'lodash';

const Operations = props => {
  const {
    visible,
    mergeActive,
    expenseMode,
    replaceAllActive,
    exportActive,
    type,
    importData,
    defaultFile,
    setIsMergeOperation,
    setImportData,
    setImportMode,
  } = props;

  const { categories } = useSelector(state => state.app);
  const inputRef = useRef(null);
  const fileName = inputRef.current?.files?.[0]?.name || defaultFile?.name || '';
  const fileExtension = fileName.split('.').pop();
  const dispatch = useDispatch();

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  if (!visible) return null;

  return (
    <Fragment>
      <FileUploader
        ref={inputRef}
        accept={expenseMode ? '.json' : '.csv'}
        callback={e => handleFileUpload({ event: e, setImportData, addNotification, getBalances, categories })}
      />

      <ImportExportContainer>
        <RemoveAndRestore
          ref={inputRef}
          replaceAllActive={replaceAllActive}
          type={type}
          expenseMode={expenseMode}
          setImportData={setImportData}
          setImportMode={setImportMode}
          setIsMergeOperation={setIsMergeOperation}
        />

        <Merge
          ref={inputRef}
          mergeActive={mergeActive}
          type={type}
          visible={_.toLower(type) !== 'transactions'}
          expenseMode={expenseMode}
          setImportData={setImportData}
          setImportMode={setImportMode}
          setIsMergeOperation={setIsMergeOperation}
        />

        <Export
          ref={inputRef}
          exportActive={exportActive}
          type={type}
          expenseMode={expenseMode}
          setImportData={setImportData}
          setImportMode={setImportMode}
          setIsMergeOperation={setIsMergeOperation}
        />

        <FileArea
          ref={inputRef}
          importData={importData}
          fileName={fileName}
          fileExtension={fileExtension}
          setImportData={setImportData}
        />

        {!exportActive && <XymSpacer />}

        <Note
          type={type}
          expenseMode={expenseMode}
          visible={exportActive}
        />
      </ImportExportContainer>
    </Fragment>
  );
};

export { Operations };