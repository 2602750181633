import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { institutionsActions, expensesActions } from 'modules';
import { UL, Font, Footnote, XymSpacer, XymConfirmButtons } from 'components';
import { StyledConfirm } from './styles';
import { styled, withTheme } from 'styled-components';
import _ from 'lodash';

const RemovedItem = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 2rem;
`;

const RemoveTransactionsConfirm = withTheme(props => {
  const { theme, setConfirmContent } = props;
  const { tokenInfo } = useSelector(state => state.auth);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);
  const { transactionsData, selectedAccountId, paginationData, searchQuery } = useSelector(state => state.institutions);

  const { currentPage } = paginationData || {};
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;
  const error = theme.modes[selectedTheme]?.error;
  const errorHover = theme.modes[selectedTheme]?.errorHover;
  const dispatch = useDispatch();

  const removeManualTransactions = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.removeManualTransactions(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  return (
    <StyledConfirm>
      <Font weight='semibold'>
        Are you sure you want to remove all transactions?
      </Font>

      <RemovedItem>
        <XymSpacer size={2} mobileSize={1} />
        <Font>The following will be removed:</Font>
        <XymSpacer size={0.875} mobileSize={1} />

        <UL>
          <li>All transactions</li>
          <li>All category tags</li>
          <li>All memos</li>
          <li>All auto-funding/spending associations</li>
        </UL>

        <XymSpacer size={0.875} mobileSize={1} />
        <Font weight='semibold' color={error}>This action cannot be undone!</Font>
        <XymSpacer size={1.875} mobileSize={1} />
      </RemovedItem>


      <Footnote>
        Your balances will be reset to the starting balance, set for this account in the self-managed institution
        editor, found in <Link to='/my-institutions'>My institutions</Link>.
        <br />
        <br />
        You may see a negative value for your balance if funds are allocated to your expenses and goals.  You
        can correct this by moving the funds from expenses and goals back into the safe balance.
      </Footnote>

      <XymSpacer />

      <XymConfirmButtons
        confirmText='Remove'
        privacy={privacyMode}
        setConfirmContent={setConfirmContent}
        confirmColor={error}
        confirmHoverColor={errorHover}
        callback={() => {
          if (!_.isEmpty(transactionsData)) {
            const payload = {
              token,
              linkedAccountId: selectedAccountId,
              search: !_.isEmpty(searchQuery) ? searchQuery : '',
              pageNumber: currentPage,
            };

            const callbacks = {
              onSuccess: () => {
                const balancesPayload = { token, linkedAccountId: selectedAccountId };
                getBalances(balancesPayload);
              },
            };

            removeManualTransactions(payload, callbacks);
            setConfirmContent(null);
          }
        }}
      />
    </StyledConfirm>
  );
});

export { RemoveTransactionsConfirm };