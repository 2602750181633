
// =============== Authentication ===============
export const login = args => {
  const { type, payload, callbacks } = args;

 return {
    type,
    path: '/auth/login',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const verifyToken = args => {
  const { type, payload, callbacks } = args;

  return {
    type,
    path: '/auth/verify-token',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const getNewTokens = args => {
  const { type, payload, callbacks } = args;
  const { meta, ...withoutMeta } = payload;

  return {
    type,
    path: '/auth/new-tokens',
    method: 'POST',
    body: JSON.stringify(withoutMeta),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    meta,
    ...callbacks,
  };
};

export const invalidateToken = args => {
  const { type, payload, callbacks } = args;

  return {
    type,
    path: '/auth/invalidate-token',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};


// ==================== User ====================
export const createUser = args => {
  const { type, payload, callbacks } = args;

 return {
    type,
    path: '/users',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const getUserInfo = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

  return {
    type,
    path: '/users/me',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const sendVerificationEmail = args => {
  const { type, payload, callbacks } = args;

 return {
    type,
    path: '/auth/request-email-verification',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const verifyEmail = args => {
  const { type, payload, callbacks } = args;

  return {
    type,
    path: '/auth/verify-email',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const sendPasswordResetEmail = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

 return {
    type,
    path: '/auth/reset-password',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updatePassword = args => {
  const { type, payload, callbacks } = args;

 return {
    type,
    path: '/auth/set-password',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const deleteUser = args => {
  const { type, payload, callbacks } = args;
  const { token, ...withoutToken } = payload;

 return {
    type,
    path: '/users',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};


// ================== Waitlist ==================
export const addUserToWaitlist = args => {
  const { type, payload, callbacks } = args;

  return {
    type,
    path: '/admin/waitlist',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const removeUserFromWaitlist = args => {
  const { type, payload, callbacks } = args;

  return {
    type,
    path: '/admin/waitlist',
    method: 'DELETE',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};

export const getWaitlistInviteCode = args => {
  const { type, payload, callbacks } = args;

  return {
    type,
    path: '/admin/invites',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...callbacks,
  };
};


// ================== Statistics ==================
export const getUserStatistics = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

  return {
    type,
    path: '/admin/user-statistics',
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};