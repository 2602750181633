
export const getInstitutions = args => {
  const { type, callbacks, payload } = args;
  const { token } = payload;

  return {
    type,
    path: '/institutions',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const getTransactions = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, pageNumber, pageLimit, search, linkedAccountId, existingDataOnly } = payload;

  const linkedAccountParam = `?linkedAccountId=${linkedAccountId || null}`;
  const pageNumberParam = pageNumber && `&pageNumber=${pageNumber}`;
  const pageLimitParam = pageLimit && `&pageLimit=${pageLimit}`;
  const pageParams = `${pageNumberParam || ''}${pageLimitParam || ''}`;
  const searchParam = search && `&search=${JSON.stringify(search)}`;
  const existingDataOnlyParam = existingDataOnly && `&existingDataOnly=${existingDataOnly}`;
  const params = `${linkedAccountParam}${pageParams}${searchParam || ''}${existingDataOnlyParam || ''}`;

  return {
    type,
    path: `/institutions/transactions${params}`,
    method: 'GET',
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const importTransactionsFromFile = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/transactions',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const removeManualTransactions = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/transactions',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateInstitutionErrorState = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/items/error-state',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const getLinkToken = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/plaid/create-link-token',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updatePlaidLink = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/plaid/update',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const exchangePublicToken = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/plaid/exchange-public-token',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const removeInstitution = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateTransaction = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/transaction',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const addTransaction = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/transaction',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const removeTransaction = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/transaction',
    method: 'DELETE',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const purgeUnsubscribedUserData = args => {
  const { type, payload, callbacks } = args;
  const { token } = payload;

  return {
    type,
    path: '/admin/purge-unsubscribed-data',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const addSelfManagedInstitution = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/self-managed',
    method: 'POST',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};

export const updateSelfManagedInstitution = args => {
  const { type, payload, callbacks } = args;
  const { token, meta, ...withoutToken } = payload;

  return {
    type,
    path: '/institutions/self-managed',
    method: 'PUT',
    body: JSON.stringify(withoutToken),
    meta,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    ...callbacks,
  };
};