import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { theme } from 'theme';
import styled from 'styled-components';

const { themes, spacing, breakpoints } = appConstants;
const { mobile } = breakpoints;
const { gap, smallGap } = spacing;
const { light } = themes;
const { colors } = theme;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media screen and (max-width: ${mobile}rem) {
    padding-bottom: 9rem;
  }
`;

export const TransactionTitle = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: ${gap}rem;
  width: 100%;

  #slideOverPrice {
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  }
`;

export const Address = styled('div')`
  display: ${props => props.$visible ? 'flex' : 'none'};
  flex-direction: column;
  width: 100%;
`;

export const Map = styled('div')`
  display: ${props => props.$visible ? 'none' : 'flex'};
  width: 100%;
  min-height: 12rem;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${props => {
    const lightTheme = props.$selectedTheme === light;
    const frenchGrey = colors.neutral.frenchGrey;
    const darkGrey = getColor(props, 'darkGrey');
    return lightTheme ? frenchGrey + 50 : darkGrey;
  }};
`;

export const Details = styled('div')`
  padding: 0 ${smallGap}rem;
  width: 100%;
`;

export const CityStateZip = styled('div')`
  display: inline-flex;
`;

export const Pending = styled('div')`
  display: inline-flex;
  align-items: center;
`;

export const TimeAndType = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;