import { useState, Fragment, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { appActions } from 'modules';
import { institutionByAccountId, handleFileUpload } from 'helpers';
import { ImportExportWizard } from 'pages/App/Header/ImportExportWizard/ImportExportWizard';
import { DateHeader } from './DateHeader';
import { TransactionItem } from './TransactionItem';
import { Font, NoData } from 'components';
import { ActivityArea } from './styles';
import { Loading } from 'xerum';
import { withTheme } from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

const ActivityList = withTheme(props => {
  const { theme, setConfirmContent } = props;

  const { mobileMode, categories } = useSelector(state => state.app);
  const { expensesDataLoading } = useSelector(state => state.expenses);
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);

  const {
    institutionsData,
    transactionsData,
    transactionsDataLoading,
    institutionDataLoading,
    slideOverTransactionId,
    selectedAccountId,
    searchQuery,
  } = useSelector(state => state.institutions);

  const [ isDragging, setIsDragging ] = useState(false);

  const institution = useMemo(() => {
    return institutionByAccountId({ institutionsData, accountId: selectedAccountId });
  }, [ selectedAccountId, institutionsData ]);

  const onPrimary = theme.modes[selectedTheme]?.onPrimary;
  const dataLoading = institutionDataLoading || transactionsDataLoading || expensesDataLoading;
  const failedSearch = !_.isEmpty(searchQuery) && _.isEmpty(transactionsData);
  const isSelfManaged = institution?.selfManaged;
  const readyForCsvImport = isSelfManaged && _.isEmpty(transactionsData) && _.isEmpty(searchQuery);
  const dispatch = useDispatch();

  const addNotification = useCallback(payload => {
    dispatch(appActions.addNotification(payload));
  }, [ dispatch ]);

  const handleImportExport = ({ event, fileDropped }) => {
    event?.preventDefault();
    const baseArgs = { setConfirmContent, defaultType: 'transactions', restrictDataMode: true };

    if (readyForCsvImport && fileDropped) {
      const file = event?.dataTransfer?.files?.[0];

      handleFileUpload({
        event,
        addNotification,
        categories,
        callback: data => {
          const args = {
            ...baseArgs,
            defaultImportMode: 'transactions',
            defaultImportData: data,
            defaultFile: file,
          };

          const payload = <ImportExportWizard { ...args } />;
          setConfirmContent(payload);
        },
      });
    }

    if (readyForCsvImport && !fileDropped) {
      const payload = <ImportExportWizard { ...baseArgs } />;
      setConfirmContent(payload);
    }
  };

  const getUploadMethodText = () => {
    if (mobileMode) return 'Tap to select CSV file';
    return 'Select CSV here, or drag and drop';
  };

  const buildActivity = () => {
    if (!_.isEmpty(transactionsData) && !dataLoading) {
      return transactionsData.map((transaction, index) => {
        const { data, metadata } = transaction || {};
        const { transactionId } = metadata || {};
        const { authorized_date, date } = data || {};

        const workingDate = authorized_date || date;
        const firstItem = index === 0;
        const lastItem = index === transactionsData.length - 1;
        const nextItem = transactionsData[index + 1];
        const nextActive = nextItem?.metadata?.transactionId === slideOverTransactionId;
        const lastAuthorizedDate = transactionsData[index - 1]?.data?.authorized_date;
        const lastTxDate = transactionsData[index - 1]?.data?.date;
        const lastDateItem = lastAuthorizedDate || lastTxDate;
        const differentDay = workingDate !== lastDateItem;
        const isNewDateGroup = firstItem || differentDay;
        const firstActive = isNewDateGroup && transactionId === slideOverTransactionId;

        const rawDate = moment(workingDate, 'YYYY-MM-DD');
        const transactionYear = rawDate.year();
        const thisYear = moment().year();
        const yearFormat = transactionYear !== thisYear ? ', YYYY' : '';

        const firstDateGroupInResults = () => {
          const allDateGroups = [ ...new Set([ ...transactionsData
            ?.map(tx => tx.data.authorized_date || tx.data.date) || [] ]) ];

          const isFirstGroupInResults = _.toLower(workingDate) === _.toLower(allDateGroups[0]);

          return isFirstGroupInResults;
        };

        return (
          <Fragment key={index}>
            <DateHeader
              visible={isNewDateGroup}
              dateGroup={moment(workingDate, 'YYYY-MM-DD').format(`MMMM Do${yearFormat}`)}
              firstItem={firstItem || firstDateGroupInResults()}
              titleColor={onPrimary}
              firstActive={firstActive}
            />

            <TransactionItem transaction={transaction} lastItem={lastItem} nextActive={nextActive} />
          </Fragment>
        );
      });
    }
  };

  return (
    <ActivityArea
      $theme={theme}
      $selectedTheme={selectedTheme}
      $readyForCsvImport={readyForCsvImport}
      $isDragging={isDragging}
      onClick={e => handleImportExport({ event: e })}
      onDragOver={e => e.preventDefault()}
      onDragEnter={e => {
        e.preventDefault();
        setIsDragging(true);
      }}
      onDragLeave={e => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsDragging(false);
        }
      }}
      onDrop={e => {
        handleImportExport({ event: e, fileDropped: true });
        setIsDragging(false);
      }}
    >
      <Loading
        theme={theme}
        selectedTheme={selectedTheme}
        isLoading={dataLoading}
        hasData={!_.isEmpty(transactionsData) && !dataLoading}
        noFailIcon={true}
        renderOnFail={false}
        failText={
          <NoData
            icon={failedSearch ? 'fa-solid fa-search' : 'fa-solid fa-list'}
            text={failedSearch ? 'No results found' : 'No activity found' }
            subText={(
              <>
                {isSelfManaged && _.isEmpty(searchQuery) && (
                  <>
                    {getUploadMethodText()} to import.<br />
                    Add individual transactions using (+).
                  </>
                )}

                {!isSelfManaged && _.isEmpty(searchQuery) && 'Your transactions will appear here.'}

                {failedSearch && 'Try adjusting your search.'}
              </>
            )}
          />
        }
        text={
          <Font size={1} weight='semibold'>
            Loading activity...
          </Font>
        }
      >
        {buildActivity()}
      </Loading>
    </ActivityArea>
  );
});

export { ActivityList };