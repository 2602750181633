import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Font, H4, P, UL, XymSpacer } from 'components';
import { LegalDoc, Layout } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { VITE_APP_NAME: appName } = process.env;

const PrivacyPolicy = withTheme(props => {
  const { theme, inModal } = props;
  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const company = appName;

  return (
    <LegalDoc $theme={theme} $selectedTheme={selectedTheme}>
      <Layout $inModal={inModal}>
        {!inModal && <XymSpacer mobileSize={1} tabletSize={1} />}

        <P>
          Last Updated: March 6th, 2025
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company}Software&trade;, from now on referred to as {company},
          (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;) is committed to protecting your privacy.
          This privacy policy explains how we collect, use, disclose, and safeguard your information when
          you use our application (&quot;App&quot;) or visit our website (&quot;Site&quot;). Please read
          this privacy policy carefully. If you do not agree with the terms of this privacy policy, please
          do not access the App or Site.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>1. Information We Collect</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company} does not collect personally identifiable information (PII). We only collect the
          minimum information necessary to provide and improve our services, which includes:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Email Address:</Font>&nbsp;
            Required for account creation and communication.
          </li>

          <li>
            <Font weight='semibold'>User Preferences:</Font>&nbsp;
            Such as theme settings and sorting preferences, to enhance your experience.
          </li>

          <li>
            <Font weight='semibold'>Linked Financial Institutions:</Font>&nbsp;
            Details like institution ID&apos;s and masked account numbers, securely encrypted, are stored
            when you link your financial accounts via Plaid.
          </li>

          <li>
            <Font weight='semibold'>In-House Analytics:</Font>&nbsp;
            We use in-house analytics solely for business purposes to improve our services. No tracking
            pixels or third-party analytics services are employed. However, we do use Sentry for error
            detection, which helps our developers catch
            and fix bugs to improve the user experience.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Additionally, we do not use cookies in our application or website.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>2. How We Use Your Information</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>We use the information we collect to:</P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            Provide, operate, and maintain our services.
          </li>

          <li>
            Communicate with you, including sending service-related communications.
          </li>

          <li>
            Personalize your experience based on your preferences.
          </li>

          <li>
            Improve our services by analyzing usage patterns and trends.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>3. Sharing Your Information</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          We do not sell, trade, or otherwise transfer your information to outside parties.
          We only share your information with third-party service providers, such as:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Plaid:</Font>&nbsp;
            To securely connect your financial accounts.
          </li>

          <li>
            <Font weight='semibold'>Stripe:</Font>&nbsp;
            To process payments for our services.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          These third-party service providers have access to your information only to perform
          specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>4. Data Stored in Our Database</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company} securely stores the following information in our database, all of which is encrypted
          for your protection:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Email and Password:</Font>&nbsp;
            Used for account creation and authentication.
          </li>

          <li>
            <Font weight='semibold'>Transactions:</Font>&nbsp;
            Financial transaction data provided by you, or collected via Plaid, for in-app budgeting purposes.
          </li>

          <li>
            <Font weight='semibold'>Session Tokens and Associated Devices:</Font>&nbsp;
            For managing secure access and ensuring that only authorized devices can access your account.
          </li>

          <li>
            <Font weight='semibold'>Subscription History:</Font>&nbsp;
            Information related to your subscription, including payment history and plan details.
          </li>

          <li>
            <Font weight='semibold'>Preferences:</Font>&nbsp;
            User settings, such as theme preferences and sorting options.
          </li>

          <li>
            <Font weight='semibold'>Institutions and Accounts:</Font>&nbsp;
            Details such as institution IDs, masked account numbers, and account-related information.
          </li>

          <li>
            <Font weight='semibold'>Expenses and Goals:</Font>&nbsp;
            Data related to your financial goals and expense tracking.
          </li>

          <li>
            <Font weight='semibold'>Messages and In-App Correspondence:</Font>&nbsp;
            Any communications or correspondence within the app.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Please note that no payment information, such as credit card or bank
          account numbers, is stored within our system. All payment processing is securely
          handled by our third-party payment processor, Stripe.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Additionally, we do not collect any KYC (Know Your Customer) information
            at this time. However, we reserve the right to do so in the future if our services require
            it to meet regulatory compliance.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          All of this information is encrypted to ensure your data is protected at all times.
          For more details on our security practices, please visit
          our <Link to='/security-policy'>Security Policy</Link>.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>5. Data Removal</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          You have the right to delete your account and all associated data at any time. You can do this in two ways:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Self-Service Account Deletion:</Font>&nbsp;
            You can delete your account directly through the app. When you do this, all your data will be completely
            removed from our systems.
          </li>

          <li>
            <Font weight='semibold'>Request to Support:</Font>&nbsp;
            If you prefer, you can contact our support team
            at <a href='mailto:support@{_.toLower(company)}.com'>support@{_.toLower(company)}software.com</a> to request
            the deletion of your account. Once your request is processed, all your data will be
            completely removed from our systems.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          In both cases, we store a cryptographic hash of your email address after your data is
          deleted. This hash is not your actual email and cannot be used to recreate your
          account or identify you. It is used solely to prevent fraudulent behavior, such as
          recreating accounts multiple times to exploit promotions or services.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Once your account is deleted, the hash is the only information retained, and all other data is permanently
          erased from our system.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>6. Your Rights</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>Depending on your location, you may have the following rights regarding your information:</P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Right to Access:</Font>&nbsp;
            You can request a copy of the information we hold about you.
          </li>

          <li>
            <Font weight='semibold'>Right to Rectification:</Font>&nbsp;
            You can request that we correct any inaccurate or incomplete information.
          </li>

          <li>
            <Font weight='semibold'>Right to Erasure:</Font>&nbsp;
            You can request that we delete your information, subject to certain conditions.
          </li>

          <li>
            <Font weight='semibold'>Right to Restrict Processing:</Font>&nbsp;
            You can request that we limit the processing of your information.
          </li>

          <li>
            <Font weight='semibold'>Right to Data Portability:</Font>&nbsp;
            You can request that we provide your information in a structured, commonly used,
            and machine-readable format.
          </li>

          <li>
            <Font weight='semibold'>Right to Object:</Font>&nbsp;
            You can object to the processing of your information, including for direct marketing purposes.
          </li>

          <li>
            <Font weight='semibold'>Right to Withdraw Consent:</Font>&nbsp;
            If we are processing your data based on your consent, you have the right to withdraw
            that consent at any time.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          To exercise these rights, please contact us
          at <a href='mailto:support@{company}.com'>support@{_.toLower(company)}software.com</a>.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>7. California Consumer Privacy Act (CCPA)</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          If you are a California resident, you have additional rights under the California Consumer
          Privacy Act (CCPA), including:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Right to Know:</Font>&nbsp;
            You have the right to request that we disclose the categories and specific pieces of information we have
            collected about you.
          </li>

          <li>
            <Font weight='semibold'>Right to Delete:</Font>&nbsp;
            You have the right to request the deletion of information we have collected from you,
            subject to certain exceptions.
          </li>

          <li>
            <Font weight='semibold'>Right to Opt-Out:</Font>&nbsp;
            You have the right to opt-out of the sale of your personal information.
          </li>

          <li>
            <Font weight='semibold'>Right to Non-Discrimination:</Font>&nbsp;
            We will not discriminate against you for exercising any of your CCPA rights.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          <Font weight='semibold'>
            Please note that we do not collect or sell personally identifiable information (PII).
          </Font>
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>To exercise these rights, please contact us
          at <a href={`mailto:support@${_.toLower(company)}.com`}>support@{_.toLower(company)}software.com</a>.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>8. Data Retention</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          We retain your information only for as long as necessary to fulfill the purposes outlined
          in this privacy policy unless a longer retention period is required or permitted by law.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>9. International Data Transfers</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          If you are accessing our services from outside the United States, please be aware that your information may be
          transferred to, stored, and processed in the United States. We take appropriate steps to ensure that your
          information is treated securely and in accordance with this privacy policy and applicable
          data protection laws.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>10. Third-Party Services</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Our services may contain as to third-party websites or services, such as payment processors
          or financial institutions, which are not operated by {company}. Please be aware that we are not
          responsible for the privacy practices or the content of these third-party websites or services.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          When you use these third-party services, any information you provide is governed by their
          respective privacy policies. We strongly recommend that you review the privacy policies of
          any third-party services you engage with to understand their practices regarding your information.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          {company} is not liable for any misuse of your information by any third-party service or for any actions taken
          by third parties in relation to your information.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>11. Opt-Out of Communications</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          We respect your privacy and provide you with the ability to opt-out of receiving certain
          communications from us. If you no longer wish to receive marketing emails or newsletters,
          you can opt-out by:
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <UL>
          <li>
            <Font weight='semibold'>Using the Unsubscribe link:</Font>&nbsp;
            Each marketing email or newsletter you receive from us will contain an unsubscribe link
            at the bottom. You can click this link to immediately stop receiving further communications.
          </li>

          <li>
            <Font weight='semibold'>Contacting Us Directly:</Font>&nbsp;
            You can also contact us
            at <a href='mailto:support@{_.toLower(company)}.com'>support@{_.toLower(company)}software.com</a> to
            request that you be removed from our marketing list.
          </li>
        </UL>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          Please note that even if you opt-out of marketing communications, you may still receive service-related
          communications that are necessary for the operation of your account or the App.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>12. Changes to This privacy policy</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          We may update this privacy policy from time to time to reflect changes in our practices or legal
          requirements. We will notify you of any <em>significant</em> changes by posting the new privacy policy
          on our Site and updating the &quot;Last Updated&quot; date at the top of this policy.
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} size={2} />

        <H4>13. Contact Us</H4>

        <XymSpacer mobileSize={1} tabletSize={1} />

        <P>
          If you have any questions about this privacy policy or our privacy practices, please contact us at&nbsp;
          <a href='mailto:support@{_.toLower(company)}.com'>support@{_.toLower(company)}software.com</a>
        </P>

        <XymSpacer mobileSize={1} tabletSize={1} />
      </Layout>
    </LegalDoc>
  );
});

export { PrivacyPolicy };