import { appConstants } from 'modules';
import { getColor } from 'helpers';
import { theme } from 'theme';
import styled, { css } from 'styled-components';

const { themes, breakpoints, spacing } = appConstants;
const { smallGap } = spacing;
const { tabletLandscape, mobile, hybrid } = breakpoints;
const { light } = themes;
const colors = theme.colors;

const getDragDropColor = (props, isBorder) => {
  const lightTheme = props.$selectedTheme === light;
  const paynesGrey = colors.neutral.paynesGrey;
  const frenchGrey = colors.neutral.frenchGrey;
  const borderColor = lightTheme ? frenchGrey : paynesGrey;

  if (isBorder) return borderColor;
  return frenchGrey + 15;
};

export const StyledActivity = styled('div')`
  grid-area: activity;
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr auto;
  height: 100%;
  width: 100%;

  & > *:not(:last-child) {
    gap: ${smallGap}rem;
  }

  @media screen and (max-width: ${hybrid}rem) {
    height: calc(100% - 4rem);
  }
`;

export const TitleArea = styled('div')`
  position: relative;
  display: grid;
  grid-template-columns: ${props => props.$isSelfManaged ? '1fr repeat(2, auto)' : '1fr auto'};
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'title removeManualTransactions addTransaction'
    'search search search';


  align-items: center;
  gap: 0 ${smallGap}rem;
  width: 100%;

  ${props => !props.$isSelfManaged && css`
    gap: 0;
    grid-template-areas:
      'title refresh'
      'search search';
  `}

  #activity {
    grid-area: title;
  }

  #refresh {
    grid-area: refresh;
    min-height: 3rem;
  }

  #activitySearch {
    grid-area: search;
  }

  #addTransaction {
    grid-area: addTransaction;
    min-height: 3rem;
  }

  #removeManualTransactions {
    grid-area: removeManualTransactions;
    min-height: 3rem;
  }

  @media screen and (max-width: ${tabletLandscape}rem) {
    grid-template-rows: 1fr;
    grid-template-areas: 'search removeManualTransactions addTransaction';

    ${props => !props.$isSelfManaged && css`
      gap: 0;
      grid-template-areas: 'search refresh';
    `}

    #refresh,
    #addTransaction,
    #removeManualTransaction {
      min-height: unset;
    }
  }
`;

export const ActivityArea = styled('div')`
  position: relative;
  padding: 0 ${smallGap}rem;
  overflow: auto;
  border-radius: 0.5rem;
  cursor: ${props => props.$readyForCsvImport ? 'pointer' : 'default'};
  border: 0.125rem solid ${props => props.$readyForCsvImport ? getDragDropColor(props, true) : 'transparent'};
  border-style: ${props => props.$readyForCsvImport ? 'dashed' : 'none'};

  ${props => props.$readyForCsvImport && props.$isDragging && css`
    background-color: ${getDragDropColor(props)};
  `}

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.$readyForCsvImport ? getDragDropColor(props) : 'transparent'};
    }
  }

  @media screen and (max-width: ${mobile}rem) {
    padding: 0;
  }
`;

export const StyledTransactionItem = styled('div')`
  display: inline-flex;
  gap: 0 0.75rem;
  width: 100%;
  min-height: 4.25rem;
  user-select: none;

  cursor: pointer;
  padding: 0.375rem 0.75rem 0.375rem ${smallGap}rem;
  border-bottom: 0.0625rem solid ${props => {
    const lightTheme = props.$selectedTheme === light;
    const color = lightTheme ? getColor(props,'lightGrey') + 95 : getColor(props, 'lightGrey') + 20;

    if (props.$lastItem || props.$nextActive) return 'transparent';
    return color;
  }};

  ${props => props.$active && css`
    position: relative;
    background-color: ${props => {
      const lightTheme = props.$selectedTheme === light;
      const color = getColor(props, lightTheme ? 'darkGrey' : 'offWhite');
      return color;
    }};
    color: ${props => getColor(props, 'primary')};
    border-bottom: none;
    border-radius: 0.25rem;
    z-index: 1;
  `}

  @media (hover: hover) {
    &:hover {
      background-color: ${props => {
        const lightTheme = props.$selectedTheme === light;
        return !props.$active && getColor(props, lightTheme ? 'lightGrey' : 'darkGrey') + (lightTheme ? 50 : '');
      }};
    }
  }
`;

export const Icon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TransactionInfo = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const TransactionDetails = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 5rem;
`;

export const Title = styled('div')`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const SubTitle = styled('div')`
  display: ${props => props.$visible ? 'inline-flex' : 'none'};
  flex-wrap: wrap;
`;

export const Amount = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
`;

export const Category = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
`;

export const RemainingAutoSpendBalance = styled('div')`
  display: inline-flex;
`;

export const SpentDetails = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const SpentFromText = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledConfirm = styled('div')`
  width: 27rem;

  @media screen and (max-width: 53.75rem) {
    width: 100%;
  }
`;