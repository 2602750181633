import { useSelector } from 'react-redux';
import { iconValid } from 'helpers';
import { Font } from 'components';
import { IconArea } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const ExpenseIcon = withTheme(props => {
  const {
    theme,
    icon,
    alwaysVisible,
    size,
    bgColorLight,
    bgColorDark,
    fontSize,
    name,
    noMargin,
    borderRadius,
    logo,
  } = props;

  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);

  const renderIcon = () => {
    if (!alwaysVisible && privacyMode) {
      return (
        <Font size={fontSize}>
          <i className='fa-solid fa-lock' />
        </Font>
      );
    }

    if (iconValid(icon)) {
      return (
        <Font size={fontSize}>
          <i className={icon} />
        </Font>
      );
    }

    if (logo) {
      return (
        <img src={logo} alt={`${name} logo`} />
      );
    }

    return (
      <Font size={fontSize || 0.75} weight='medium'>
        {_.capitalize(name).slice(0, 2)}
      </Font>
    );
  };

  return (
    <IconArea
      $theme={theme}
      $selectedTheme={selectedTheme}
      $size={size}
      $borderRadius={borderRadius}
      $noMargin={noMargin}
      $logo={logo}
      $bgColorLight={bgColorLight}
      $bgColorDark={bgColorDark}
    >
      {renderIcon()}
    </IconArea>
  );
});

export { ExpenseIcon };