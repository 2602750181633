import { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  XymField,
  XymSelect,
  Font,
  XymSpacer,
  Footnote,
  XymConfirmButtons,
  Close,
  NoData,
  InstitutionIcon,
} from 'components';
import { institutionsActions, expensesActions } from 'modules';
import { customStartCase, cleanCurrencyString } from 'helpers';
import { InstitutionName } from 'pages/MyInstitutions/Accounts/styles';
import { Account } from 'pages/MyInstitutions/Accounts/Account';
import { AccountBuilder, AddAccountArea, AccountListArea, RemoveItem, AccountItemHolder, DetailFields } from './styles';
import { Formik, Form } from 'formik';
import { Button } from 'xerum';
import { withTheme } from 'styled-components';
import * as yup from 'yup';
import _ from 'lodash';

const accountTypeOptions = [
  { value: 0, label: 'Select an account type...' },
  { value: 1, label: 'Checking' },
  { value: 2, label: 'Savings' },
];

const SelfManagedSetupForm = withTheme(props => {
  const { theme, editMode, setConfirmContent, itemId } = props;

  const { tokenInfo } = useSelector(state => state.auth);
  const { mobileMode, tabletMode, tabletLandscapeMode, hybridMode } = useSelector(state => state.app);
  const { institutionsData, selectedAccountId } = useSelector(state => state.institutions);
  const { generalPreferences: { privacyMode, selectedTheme } } = useSelector(state => state.preferences);

  const existingInstitution = institutionsData.find(institution => institution.itemId === itemId);
  const existingAccounts = existingInstitution?.accounts.map(account => ({
    accountId: account.account_id,
    accountMask: account.mask,
    startingBalance: account.balances.available,
    accountType: account.subtype,
    accountName: account.name,
  }));

  const [ workingName, setWorkingName ] = useState(existingInstitution?.institutionName || '');
  const [ workingAccounts, setWorkingAccounts ] = useState(!_.isEmpty(existingAccounts) ? existingAccounts : []);
  const [ workingAccount, setWorkingAccount ] = useState(null);
  const [ pulseExisting, setPulseExisting ] = useState(false);

  const desktopMode = !mobileMode && !tabletMode && !tabletLandscapeMode && !hybridMode;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const white = theme.modes[selectedTheme]?.white;
  const token = tokenInfo?.refreshToken || tokenInfo?.accessToken;

  const hasChanges = useCallback(args => {
    const { currentName } = args || {};
    const hasNewName = !_.isEqual(_.toLower(currentName), _.toLower(existingInstitution?.institutionName));
    const accountsHaveChanged = !_.isEqual(existingAccounts, workingAccounts);

    return editMode ? (accountsHaveChanged || hasNewName) : !_.isEmpty(workingAccounts);
  }, [ editMode, existingInstitution, existingAccounts, workingAccounts ]);

  const dispatch = useDispatch();

  const addSelfManagedInstitution = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.addSelfManagedInstitution(payload, callbacks));
  }, [ dispatch ]);

  const getBalances = useCallback((payload, callbacks) => {
    dispatch(expensesActions.getBalances(payload, callbacks));
  }, [ dispatch ]);

  const updateSelfManagedInstitution = useCallback((payload, callbacks) => {
    dispatch(institutionsActions.updateSelfManagedInstitution(payload, callbacks));
  }, [ dispatch ]);

  const institutionExists = value => institutionsData?.find(institution => {
    const sameName = _.toLower(institution.institutionName) === _.toLower(value);
    const sameId = institution.itemId === itemId;

    if (editMode) return sameName && !sameId;
    return sameName;
  });

  const accountExists = mask => {
    const match = workingAccounts.find(account => account.accountMask === mask);
    return !_.isEmpty(match);
  };

  const defaults = {
    institutionName: workingName || '',
    accountName: workingAccount?.accountName || '',
    startingBalance: _.toString(workingAccount?.startingBalance) || '',
    accountMask: workingAccount?.accountMask || '',
    accountType: accountTypeOptions.find(option => (
      _.toLower(option.label) === _.toLower(workingAccount?.accountType)
    ))?.value || 0,
  };

  const validationSchema = yup.object().shape({
    institutionName: yup.string().required('Please enter an institution name.')
      .test('institutionExists', 'Institution already exists.', value => !institutionExists(value)),
    accountName: yup.string().required('Please enter an account name.'),
    startingBalance: yup.string().required('Please enter starting balance.')
      .test('isNumeric', 'Starting balance must be a number.', value => {
        const valueAsNumber = cleanCurrencyString(value);
        return !Number.isNaN(valueAsNumber);
    }),
    accountMask: yup.string().required('Required.')
      .test('length', 'Not 4 digits.', value => value?.length === 4)
      .test('isNumeric', 'Not a number.', value => !Number.isNaN(+value))
      .test('accountExists', 'Already added.', value => {
        const isActiveSelection = workingAccount?.accountMask === value;
        const match = !isActiveSelection && accountExists(value);
        setPulseExisting(match);
        return !match;
      }),
    accountType: yup.number()
      .test('hasSelection', 'Please select an account type.', value => value !== 0),
  });

  useEffect(() => {
    return () => {
      setWorkingAccounts([]);
      setWorkingAccount(null);
      setPulseExisting(false);
    };
  }, []);

  const manageWorkingAccounts = args => {
    const { values, setSubmitting, resetForm } = args || {};
    const { institutionName, startingBalance, accountMask, accountName, accountType } = values;
    const typeLabel = accountTypeOptions.find(option => option.value === +accountType)?.label;
    const cleanedUpName = accountName.replace(/Checking|checking|Savings|savings/g, '').trim();
    const isActiveSelection = workingAccount?.accountMask === accountMask;

    const hasFieldValues = !_.isEmpty(institutionName)
      && +accountType !== 0
      && !_.isEmpty(accountName)
      && !_.isEmpty(startingBalance)
      && !_.isEmpty(accountMask);

    const validUpdate = hasFieldValues && isActiveSelection && accountExists(accountMask);
    const validAddition = hasFieldValues && !isActiveSelection && !accountExists(accountMask);

    const newAccount = {
      accountId: (isActiveSelection && workingAccount?.accountId) || null,
      startingBalance: _.isEmpty(startingBalance)
        ? 0
        : cleanCurrencyString(startingBalance),
      accountMask,
      accountType: _.toLower(typeLabel),
      accountName: `${cleanedUpName} ${customStartCase(typeLabel)}`,
    };

    if (validUpdate) {
      const withUpdatedAccount = workingAccounts.map(account => {
        const match = account.accountMask === accountMask;

        if (match) return newAccount;
        return account;
      });

      setWorkingAccounts(withUpdatedAccount);
    }

    if (validAddition) setWorkingAccounts([ ...workingAccounts, newAccount ]);

    setWorkingName(institutionName);
    setWorkingAccount(null);
    resetForm();
    setSubmitting(false);
  };

  const loadAccountValues = args => {
    const { form, account, isActiveSelection } = args || {};

    if (!isActiveSelection) {
      setWorkingAccount(account);
      return;
    }

    form.resetForm();
    setWorkingAccount(null);
  };

  const renderWorkingAccounts = args => {
    const { form } = args || {};
    const accounts = workingAccounts.map((account, index) => {
      const { accountMask, startingBalance, accountName } = account;
      const isActiveSelection = workingAccount?.accountMask === accountMask;
      const matchingMask = accountMask === form.values.accountMask;

      const renderPayload = {
        name: accountName,
        type: 'Depository',
        startingBalance: startingBalance,
        mask: accountMask,
      };

      return (
        <AccountItemHolder key={index} onClick={() => loadAccountValues({ form, account, isActiveSelection })}>
          <RemoveItem
            $theme={theme}
            $selectedTheme={selectedTheme}
            onClick={e => {
              e.stopPropagation();

              const withoutAccount = workingAccounts.filter(account => (
                !_.isEqual(account, workingAccounts[index])
              ));

              setWorkingAccounts(withoutAccount);
              setWorkingAccount(null);
            }}
          >
            <Close width={1.25} height={1.25} color={white} bgColor={paynesGrey} />
          </RemoveItem>

          <Account
            account={renderPayload}
            displayOnly={true}
            fullWidth={true}
            pulseSelect={!isActiveSelection && matchingMask && pulseExisting}
            isActiveSelection={isActiveSelection}
          />

          {index !== (workingAccounts.length - 1) && <XymSpacer size={1.5} />}
        </AccountItemHolder>
      );
    });

    return (
      <Fragment>
        {_.isEmpty(accounts)
          ? <NoData
              icon={<InstitutionIcon />}
              text='No accounts added'
              subText={(
                <>
                  Accounts will show here<br />
                  Select to update
                  {desktopMode ? ', hover to remove' : '.'}.
                </>
              )}
            />
          : <>{accounts}</>
        }
      </Fragment>
    );
  };

  const shouldValidate = values => {
    const { institutionName, accountType, accountName, startingBalance, accountMask } = values || {};
    const errors = {};

    const aFieldHasValue = +accountType !== 0
      || !_.isEmpty(accountName)
      || !_.isEmpty(startingBalance)
      || !_.isEmpty(accountMask);

    const hasAccountsWhileFillingMoreFields = !_.isEmpty(workingAccounts) && aFieldHasValue;
    const runValidation = _.isEmpty(workingAccounts)
      || _.isEmpty(institutionName)
      || institutionExists(institutionName)
      || hasAccountsWhileFillingMoreFields;

    if (!runValidation) return errors;

    try {
      validationSchema.validateSync(values, { abortEarly: false });
    } catch (error) {
      error.inner.forEach(error => errors[error.path] = error.message);
    }

    return errors;
  };

  const getConfirmText = value => {
    if (_.isEmpty(workingAccounts)) return 'Awaiting accounts...';
    if (!hasChanges({ currentName: value })) return 'No changes to save...';

    return 'Save changes';
  };

  return (
    <Formik
      initialValues={defaults}
      validate={shouldValidate}
      enableReinitialize={true}
      onSubmit={(values, handlers) => {
        const { setSubmitting, resetForm } = handlers;
        manageWorkingAccounts({ values, setSubmitting, resetForm });
      }}
    >
      {form => (
        <Form>
          <Font size={1} weight='semibold'>Institution name</Font>

          <XymSpacer size={0.5} />

          <XymField
            form={form}
            name='institutionName'
            placeholder='i.e. Chase, Ally, Chime, etc.'
            privacy={privacyMode}
            spacing={2}
          />

          <Font size={1} weight='semibold'>
            {
              !_.isEmpty(workingAccounts)
                ? 'Add more accounts, or save changes to begin...'
                : 'Add your accounts...'
            }
          </Font>

          <XymSpacer size={1.25} />

          <AccountBuilder>
            <AddAccountArea>
              <XymSelect
                form={form}
                data={accountTypeOptions}
                name='accountType'
                privacy={privacyMode}
                label={<Font size={0.875} weight='semibold'>What type of account is this?</Font>}
              />

              <XymField
                form={form}
                name='accountName'
                placeholder="i.e. Emily's Checking"
                privacy={privacyMode}
                label={<Font size={0.875} weight='semibold'>Account name</Font>}
              />

              <DetailFields>
                <div>
                  <XymField
                    form={form}
                    name='startingBalance'
                    placeholder='Dollar signs and commas okay'
                    spacing={0.5}
                    privacy={privacyMode}
                    disabled={false}
                    label={(
                      <Font size={0.875} weight='semibold'>
                        Starting balance
                      </Font>
                    )}
                  />
                </div>

                <div>
                  <XymField
                    form={form}
                    name='accountMask'
                    placeholder='i.e. 0000'
                    spacing={0.5}
                    disabled={false}
                    privacy={privacyMode}
                    label={<Font size={0.875} weight='semibold'>Last 4 of account #</Font>}
                  />
                </div>
              </DetailFields>

              <XymSpacer size={0.5} />

              <Footnote title='About starting balance:'>
                Your starting balance keeps your account in sync with your institution. Importing a CSV overrides
                it, recalculating the balance from imported data.
                <br />
                <br />
                If your CSV is incomplete and your account balance does not match your institution&apos;s,
                after importing, adjust the starting balance to match your institution&apos;s balance &mdash; clearing
                all transactions resets it to this value.
                <br />
                <br />
                <Font size={0.75} weight='semibold'>
                  It is not recommended to adjust your starting balance, after setup, to compensate for
                  missing transactions!
                </Font>
              </Footnote>

              <XymSpacer />

              <Button
                theme={theme}
                selectedTheme={selectedTheme}
                type='submit'
                text={(
                  <Font weight='medium' mobileSize={0.875}>
                    {workingAccount?.accountMask === form.values.accountMask
                      ? 'Update account'
                      : `Add${_.isEmpty(workingAccounts) ? ' ' : ' new '}account`
                    }
                  </Font>
                )}
                callback={form.submitForm}
              />
            </AddAccountArea>

            <AccountListArea $theme={theme} $selectedTheme={selectedTheme} $noAccounts={_.isEmpty(workingAccounts)}>
              <InstitutionName $theme={theme} $selectedTheme={selectedTheme}>
                <Font weight='semibold'>
                  {customStartCase(form.values.institutionName)}
                </Font>
              </InstitutionName>

              {renderWorkingAccounts({ form })}
            </AccountListArea>
          </AccountBuilder>

          <XymSpacer size={1.5} />

          <XymConfirmButtons
            confirmText={getConfirmText(form.values.institutionName)}
            disabled={
              !_.isEmpty(form.errors)
              || form.isSubmitting
              || !hasChanges({ currentName: form.values.institutionName })
              || _.isEmpty(workingAccounts)
              || (institutionExists(form.values.institutionName))
            }
            setConfirmContent={setConfirmContent}
            callback={() => {
              const payload = {
                token,
                institutionName: customStartCase(form.values.institutionName),
                itemId: editMode && existingInstitution?.itemId,
                accounts: workingAccounts,
              };

              const callbacks = {
                onSuccess: () => {
                  const balancesPayload = { token, linkedAccountId: selectedAccountId };
                  setConfirmContent(null);
                  getBalances(balancesPayload);
                },
                onComplete: () => form.setSubmitting(false),
              };

              form.setSubmitting(true);

              editMode
                ? updateSelfManagedInstitution(payload, callbacks)
                : addSelfManagedInstitution(payload, callbacks);
            }}
          />
        </Form>
      )}
    </Formik>
  );
});

export { SelfManagedSetupForm };