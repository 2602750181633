import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { appConstants } from 'modules';
import { ExpenseIcon, Font, XymSpacer, P } from 'components';
import { ImportExportMode } from './styles';
import { withTheme } from 'styled-components';
import _ from 'lodash';

const { names } = appConstants;
const { appName } = names;

const RemoveAndRestore = withTheme(forwardRef((props, ref) => {
  const { theme, replaceAllActive, type, expenseMode, setImportData, setImportMode, setIsMergeOperation } = props;

  const { generalPreferences: { selectedTheme } } = useSelector(state => state.preferences);
  const { transactionsData } = useSelector(state => state.institutions);
  const { expensesData } = useSelector(state => state.expenses);
  const data = expenseMode ? expensesData : transactionsData;
  const paynesGrey = theme.colors.neutral.paynesGrey;
  const modeElements = expenseMode ? 'expenses and goals' : type;

  return (
    <ImportExportMode
      $theme={theme}
      $selectedTheme={selectedTheme}
      $active={replaceAllActive}
      $visible={true}
      onClick={() => {
        ref.current.value = '';
        setImportData(null);
        setImportMode(true);
        setIsMergeOperation(false);
        ref.current.click();
      }}
    >
      <ExpenseIcon
        icon='fa-solid fa-eraser'
        bgColorDark={replaceAllActive && paynesGrey}
        size={3}
        fontSize={1.5}
        alwaysVisible={true}
      />

      <div>
        <Font weight='semibold'>
          {!_.isEmpty(data) && 'Remove and restore from backup.'}
          {_.isEmpty(data) && `Restore ${type} from backup.`}
        </Font>

        <XymSpacer size={0.125} />

        <P size={0.75}>
          {!_.isEmpty(data) && `Removes all existing ${modeElements} before importing `}
          {_.isEmpty(data) && `Imports a set of ${modeElements}, `}

          {expenseMode && `from a previously exported ${appName} JSON file, for use with this account.`}
          {!expenseMode
            && `from an institution's CSV file, or previously exported ${appName} CSV file, for use with this account.`}
        </P>
      </div>
    </ImportExportMode>
  );
}));

export { RemoveAndRestore };