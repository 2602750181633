import { actionCreator } from 'helpers';
import { institutionsConstants } from 'modules';
import * as api from 'modules/institutions/institutionsApi';

const institutionsActions = {
  setSlideOverTransactionId: payload => (
    actionCreator(institutionsConstants.actions.SET_SLIDE_OVER_TRANSACTION_ID, payload)
  ),

  setLinkToken: payload => actionCreator(institutionsConstants.actions.SET_LINK_TOKEN, payload),
  setSelectedAccountId: payload => actionCreator(institutionsConstants.actions.SET_SELECTED_ACCOUNT_ID, payload),
  clearTransactionsData: payload => actionCreator(institutionsConstants.actions.CLEAR_TRANSACTIONS_DATA, payload),
  setSearchQuery: payload => actionCreator(institutionsConstants.actions.SET_SEARCH_QUERY, payload),

  setTransactionsDataLoading: payload => (
    actionCreator(institutionsConstants.actions.SET_TRANSACTIONS_DATA_LOADING, payload)
  ),

  setTransactionsImporting: payload => (
    actionCreator(institutionsConstants.actions.SET_TRANSACTIONS_IMPORTING, payload)
  ),

  getInstitutions: (payload, callbacks) => {
    const type = institutionsConstants.actions.GET_INSTITUTIONS_DATA;
    const args = { type, payload, callbacks };
    return api.getInstitutions(args);
  },

  getTransactions: (payload, callbacks) => {
    const type = institutionsConstants.actions.GET_TRANSACTIONS_DATA;
    const args = { type, payload, callbacks };
    return api.getTransactions(args);
  },

  getLinkToken: (payload, callbacks) => {
    const type = institutionsConstants.actions.GET_LINK_TOKEN;
    const args = { type, payload, callbacks };
    return api.getLinkToken(args);
  },

  updatePlaidLink: (payload, callbacks) => {
    const type = institutionsConstants.actions.UPDATE_PLAID_LINK;
    const args = { type, payload, callbacks };
    return api.updatePlaidLink(args);
  },

  exchangePublicToken: (payload, callbacks) => {
    const type = institutionsConstants.actions.EXCHANGE_PUBLIC_TOKEN;
    const args = { type, payload, callbacks };
    return api.exchangePublicToken(args);
  },

  removeInstitution: (payload, callbacks) => {
    const type = institutionsConstants.actions.REMOVE_INSTITUTION;
    const args = { type, payload, callbacks };
    return api.removeInstitution(args);
  },

  removeManualTransactions: (payload, callbacks) => {
    const type = institutionsConstants.actions.REMOVE_MANUAL_TRANSACTIONS;
    const args = { type, payload, callbacks };
    return api.removeManualTransactions(args);
  },

  updateTransaction: (payload, callbacks) => {
    const type = institutionsConstants.actions.UPDATE_TRANSACTION;
    const args = { type, payload, callbacks };
    return api.updateTransaction(args);
  },

  addTransaction: (payload, callbacks) => {
    const type = institutionsConstants.actions.ADD_TRANSACTION;
    const args = { type, payload, callbacks };
    return api.addTransaction(args);
  },


  removeTransaction: (payload, callbacks) => {
    const type = institutionsConstants.actions.REMOVE_TRANSACTION;
    const args = { type, payload, callbacks };
    return api.removeTransaction(args);
  },

  importTransactionsFromFile: (payload, callbacks) => {
    const type = institutionsConstants.actions.IMPORT_TRANSACTIONS_FROM_FILE;
    const args = { type, payload, callbacks };
    return api.importTransactionsFromFile(args);
  },


  updateInstitutionErrorState: (payload, callbacks) => {
    const type = institutionsConstants.actions.UPDATE_INSTITUTION_ERROR_STATE;
    const args = { type, payload, callbacks };
    return api.updateInstitutionErrorState(args);
  },

  purgeUnsubscribedUserData: (payload, callbacks) => {
    const type = institutionsConstants.actions.PURGE_UNSUBSCRIBED_USER_DATA;
    const args = { type, payload, callbacks };
    return api.purgeUnsubscribedUserData(args);
  },

  addSelfManagedInstitution: (payload, callbacks) => {
    const type = institutionsConstants.actions.ADD_SELF_MANAGED_INSTITUTION;
    const args = { type, payload, callbacks };
    return api.addSelfManagedInstitution(args);
  },

  updateSelfManagedInstitution: (payload, callbacks) => {
    const type = institutionsConstants.actions.UPDATE_SELF_MANAGED_INSTITUTION;
    const args = { type, payload, callbacks };
    return api.updateSelfManagedInstitution(args);
  },
};

export { institutionsActions };