import { appConstants, supportedCurrencies, validCsvHeaders, twoLetterWords } from 'modules';
import { parseCsv, normalizeCsvData } from 'helpers';
import moment from 'moment';
import _ from 'lodash';

const { messageTypes } = appConstants;

export const stripNumbersAndRepeats = (str, keepNumbers, clean) => {
  const strippedString = str
    ?.replace(keepNumbers ? null : /\d+/g, '...') // Replace all sequences of numbers with '...'
    ?.replace(/(\w)\1{3,}/gi, '...') // Replace any character repeated 4 or more times with '...'
    ?.replace(/^\.{3}|\.\.{3}$/g, '') // Remove leading and trailing '...' if they exist
    ?.replace(/^\.+/, '') // Remove any leading dots
    || str;

  return clean ? cleanString(strippedString) : strippedString;
};

export const getColor = (props, key, fallback) => {
  const { $theme, $selectedTheme } = props;

  if ($theme && $selectedTheme) {
    const colors = $theme?.modes?.[$selectedTheme];
    return colors?.[key] || fallback;
  }

  return fallback;
};

export const updateLocalStorage = (key, value) => {
  if (key) {
    const { names } = appConstants;
    const { appName } = names;
    const existingSettings = JSON.parse(localStorage.getItem(appName));
    const newSettings = { ...existingSettings, [key]: value || null };


    localStorage.setItem(appName, JSON.stringify(newSettings));
  }
};

export const updateTokenStorage = payload => {
  const { names } = appConstants;
  const { accessTokenKeyName, refreshTokenKeyName } = names;

  if (payload) {
    const { accessToken, refreshToken } = payload;

    updateLocalStorage(accessTokenKeyName, accessToken);
    updateLocalStorage(refreshTokenKeyName, refreshToken);
  }
};

export const getLocalStorageSetting = key => {
  if (key) {
    const { names } = appConstants;
    const { appName } = names;
    const existingSettings = JSON.parse(localStorage.getItem(appName));

    return existingSettings?.[key];
  }
};

export const notificationExists = (state, payload, selector) => {
  const notifications = state.get(selector);
  const exists = notifications?.find?.(notification => _.isEqual(notification, payload));

  return exists;
};

export const getOverflowState = (ref, setHasOverflow) => {
  if (ref.current) {
    const { clientHeight, scrollHeight } = ref.current;
    const overflow = scrollHeight > clientHeight;

    setHasOverflow(overflow);
  }
};

export const sort = (array, key, reverse) => {
  const sorted = array?.sort((a, b) => {
    // If key contains a /, it's a ratio operation between two keys,
    // so we need to split it divide the first item by the second and then compare.
    if (key.includes('/')) {
      const keys = key.split('/');
      const currentKey = keys[0];
      const neededKey = keys[1];
      const ratioA = a[currentKey] / a[neededKey];
      const ratioB = b[currentKey] / b[neededKey];

      if (ratioA > ratioB) return -1;
      if (ratioA < ratioB) return 1;
      return 0;
    }

    // If key contains a dot, it's a nested object, so we need to split it and traverse the object
    // Until we reach the final key we want to sort.
    if (key.includes('.')) {
      const keys = key.split('.');
      const aKey = keys.reduce((previous, current) => previous[current], a);
      const bKey = keys.reduce((previous, current) => previous[current], b);

      if (aKey < bKey) return -1;
      if (aKey > bKey) return 1;
      return 0;
    }

    // If key is dueDate, we need to use moment to compare the dates.
    if (key === 'dueDate') {
      const aDate = moment(a[key]);
      const bDate = moment(b[key]);

      if (aDate < bDate) return -1;
      if (aDate > bDate) return 1;
      return 0;
    }

    // Otherwise, we can just compare the values.
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  }) || [];

  return reverse ? sorted.reverse() : sorted;
};

export const formatNumber = num => {
  const [ integerPart, decimalPart ] = Math.abs(num).toFixed(2).split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${num < 0 ? `(-$${formattedIntegerPart}.${decimalPart})` : `$${formattedIntegerPart}.${decimalPart}`}`;
};

export const cleanCurrencyString = (string, nullable) => {
  const symbols = _.uniqBy(supportedCurrencies.map(currency => currency.symbol));
  const regexPattern = new RegExp(`[${symbols.map(symbol => `\\${symbol}`).join('')}"', ]`, 'g');
  const asNumber = _.toNumber(string?.replace(regexPattern, ''));

  return nullable && asNumber === 0 ? null : asNumber;
};

export const cleanString = value => {
  if (value) {
    const separatorsRemoved = value.replace(/[:/\\._-]/g, ' ');
    const extraSpacesRemoved = separatorsRemoved.replace(/\s+/g, ' ');

    const uppercaseAbbreviations = extraSpacesRemoved.replace(/\b\w{2}\b/g, word => (
      twoLetterWords.has(word.toLowerCase()) ? word.toLowerCase() : word.toUpperCase()
    ));

    const trimmed = uppercaseAbbreviations.trim();

    return trimmed;
  }

  return value;
};

export const handleFileUpload = args => {
  const { event, setImportData, categories, addNotification, callback } = args || {};
  event?.preventDefault();

  const file = event?.target?.files?.[0] || event?.dataTransfer?.files?.[0];

  if (file) {
    const fileName = file?.name || '';
    const fileExtension = fileName.split('.').pop();
    const reader = new FileReader();

    reader.onload = e => {
      const data = e.target?.result;
      const isJson = fileExtension === 'json';
      const isCsv = fileExtension === 'csv';

      if (isJson) {
        setImportData?.(JSON.parse(data));
        callback?.(JSON.parse(data));
        return;
      }

      if (isCsv) {
        const parsedData = parseCsv(data);
        const normalizedCsvData = normalizeCsvData({ parsedData, categories });
        const missingDate = normalizedCsvData?.find?.(row => !row.authorized_date);
        const incomingHeaders = Object.keys(parsedData[0]);
        const cleanedIncomingHeaders = incomingHeaders?.map(header => cleanString(header));
        const invalidHeaders = cleanedIncomingHeaders?.map(header => {
          const noMatch = !validCsvHeaders?.find(validHeader => header.includes(validHeader));
          return noMatch ? header : null;
        }).filter(item => item);
        const hasInvalidHeaders = !_.isEmpty(invalidHeaders);

        const continueImport = () => {
          setImportData?.(normalizedCsvData);
          callback?.(normalizedCsvData);
        };

        hasInvalidHeaders
          && addNotification?.({
            type: messageTypes.warning,
            message: `Invalid CSV header(s) found: ${invalidHeaders.join(', ')}.`,
          });

        missingDate
          && addNotification?.({
            type: messageTypes.warning,
            message: 'Invalid CSV date(s) found.',
          });

        !hasInvalidHeaders && !missingDate && continueImport();

        return;
      }

      addNotification?.({
        type: messageTypes.warning,
        message: 'Invalid file type.',
      });
    };

    reader.readAsText(file);
  }
};