const institutionsConstants = {
  actions: {
    SET_SLIDE_OVER_TRANSACTION_ID: 'modules/institutions/SET_SLIDE_OVER_TRANSACTION_ID',
    GET_INSTITUTIONS_DATA: 'modules/institutions/GET_INSTITUTIONS_DATA',
    GET_LINK_TOKEN: 'modules/institutions/GET_LINK_TOKEN',
    SET_LINK_TOKEN: 'modules/institutions/SET_LINK_TOKEN',
    EXCHANGE_PUBLIC_TOKEN: 'modules/institutions/EXCHANGE_PUBLIC_TOKEN',
    SET_SELECTED_ACCOUNT_ID: 'modules/institutions/SET_SELECTED_ACCOUNT_ID',
    REMOVE_INSTITUTION: 'modules/institutions/REMOVE_INSTITUTION',
    UPDATE_TRANSACTION: 'modules/institutions/UPDATE_TRANSACTION',
    UPDATE_INSTITUTION_ERROR_STATE: 'modules/institutions/UPDATE_INSTITUTION_ERROR_STATE',
    CLEAR_TRANSACTIONS_DATA: 'modules/institutions/CLEAR_TRANSACTIONS_DATA',
    GET_TRANSACTIONS_DATA: 'modules/institutions/GET_TRANSACTIONS_DATA',
    PURGE_UNSUBSCRIBED_USER_DATA: 'modules/institutions/PURGE_UNSUBSCRIBED_USER_DATA',
    UPDATE_PLAID_LINK: 'modules/institutions/UPDATE_PLAID_LINK',
    SET_SEARCH_QUERY: 'modules/institutions/SET_SEARCH_QUERY',
    SET_TRANSACTIONS_DATA_LOADING: 'modules/institutions/SET_TRANSACTIONS_DATA_LOADING',
    ADD_TRANSACTION: 'modules/institutions/ADD_TRANSACTION',
    REMOVE_TRANSACTION: 'modules/institutions/REMOVE_TRANSACTION',
    REMOVE_MANUAL_TRANSACTIONS: 'modules/institutions/REMOVE_MANUAL_TRANSACTIONS',
    ADD_SELF_MANAGED_INSTITUTION: 'modules/institutions/ADD_SELF_MANAGED_INSTITUTION',
    UPDATE_SELF_MANAGED_INSTITUTION: 'modules/institutions/UPDATE_SELF_MANAGED_INSTITUTION',
    SET_TRANSACTIONS_IMPORTING: 'modules/expenses/SET_TRANSACTIONS_IMPORTING',
    IMPORT_TRANSACTIONS_FROM_FILE: 'modules/institutions/IMPORT_TRANSACTIONS_FROM_FILE',
  },

  selectors: {
    STATE_KEY_SLIDE_OVER_TRANSACTION_ID: 'slideOverTransactionId',
    STATE_KEY_INSTITUTION_DATA_LOADING: 'institutionDataLoading',
    STATE_KEY_INSTITUTIONS: 'institutionsData',
    STATE_KEY_TRANSACTIONS_DATA: 'transactionsData',
    STATE_KEY_TRANSACTIONS_DATA_LOADING: 'transactionsDataLoading',
    STATE_KEY_TRANSACTIONS_IMPORTING: 'transactionsImporting',
    STATE_KEY_SELECTED_ACCOUNT_ID: 'selectedAccountId',
    STATE_KEY_PAGINATION_DATA: 'paginationData',
    STATE_KEY_SEARCH_QUERY: 'searchQuery',
    STATE_KEY_TRENDS: 'trends',
    STATE_KEY_INCOME_SOURCES: 'incomeSources',

    STATE_KEY_LINK_TOKEN: 'linkToken',
    STATE_KEY_LINK_TOKEN_LOADING: 'linkTokenLoading',
  },
};

export { institutionsConstants };